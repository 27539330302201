import { observable, action, makeObservable } from "mobx";

class OrderDetailsStore {

  constructor() {
    makeObservable(this)
  }

  // 行程
  @observable depar = {
    locationName: "",
    location: "",
    lng: "",
    lat: ""
  }

  // 订单详情
  @observable orderInfo = {
    orderId: "", // 订单 id
    state: null, // 订单状态
    totalAmount: '', // 订单详情车费
    carChannels: '', // 打车平台
    ipathOrderId: '', // 订单号
    createTime: '', // 用车时间
    mileage: '', // 里程
    useCarReason: '', // 用车理由
    totalTime: '', // 时长
    serviceType: '', // 用车类型
    vehicleNo: '', // 车辆信息
    companyBearAmount: '', // 企业支付
    userBearAmount: '' // 个人支付
  }

  // 费用明细
  @observable costInfo = {
    startAmount: '', // 订单起步价格
    roadAmount: '', // 路长费用
    durationAmount: '', // 时间费用
    couponAmount: '' // 折扣金额
  }

  // 企业审批相关
  @observable auditInfo = {
    scene: '', // 用车场景
    reviewerNameCn: '', // 审批人中文
    reviewerNameEn: '', // 审批人英文
    orderApproveState: '' // 审批状态 
  }


  // 设置用户输入用车事由
  @action.bound setUseCarReason(msg) {
    this.orderInfo.useCarReason = msg
  }

  // 设置订单详情 id
  @action.bound setOrderId(id) {
    this.orderInfo.orderId = id
  }

  // 设置订单详情数据
  @action.bound setOrderInfo(orderInfo) {
    this.orderInfo = orderInfo
  }

  // 设置费用明细
  @action.bound setCostInfo(costInfo) {
    this.costInfo = costInfo
  }

  // 设置企业审批相关
  @action.bound setAuditInfo(auditInfo) {
    this.auditInfo = auditInfo
  }
}

// eslint-disable-next-line
export default new OrderDetailsStore()