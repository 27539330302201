import globalStore from './global.store.js'
import mainStore from './main.store'
import estimateStore from './estimate.store.js'

import ordersStore from './orders.store.js'
import orderDetailsStore from './orderdetails.store'
import orderEvaluateStore from './orderevaluate.store'

import approvalStore from './approval.store'

import settingsStore from './settings.store'

import invoiceStore from './invoice.store'

import confirmStore from './confirm.store.js'
import runingOrderStore from './runingOrder.store.js'

import serviceCenterStore from './serviceCenter.store.js'


let store = {
  globalStore,
  mainStore,
  estimateStore,

  ordersStore,
  orderDetailsStore,
  orderEvaluateStore,

  approvalStore,

  invoiceStore,

  settingsStore,

  confirmStore,
  runingOrderStore,

  serviceCenterStore
}

// eslint-disable-next-line
export default store
