import { observable, action, computed, makeObservable, runInAction } from "mobx";
import webService from '../util/webService';
import gc from '../util/globalConfiguration'
import globalStore from './global.store'

class MainStore {

    constructor() {
        makeObservable(this)
    }

    // 待办事项计数
    @observable badgeCount = {
        runningOrderCount: 0,
        waitApproveOrderCount: 0,
        waitPayOrderCount: 0,
        waitPreApprovalCount: 0,
        availableCouponCount: 0
    };

    // 地图定位结果
    @observable mapCenter = [];

    // 当前定位地址
    @observable currentAddr = {}
    // 服务模式
    @observable serverType = 0;
    // 活动订单
    @observable activeOrder = [];
    // 用车场景
    @observable scene = {};
    // 用车申请单
    @observable preApply = {};
    // 起点地址
    @observable beginAddr = {
        cityName: "",
        cityCode: "",
        address: "",
        name: "",
        lat: "",
        lon: "",
        poi: "",
        departureTime: "",
        flightNumber: "",
        trainNumber:"",
        trainArr: "",
        trainDep: "",
        departTime: "",
        arrivalAirportCode: "",
        departAirportCode: "",
        // 不参与传参属性
        airTip: "",
        trainTip:"",
        arrivalTime: "",
        flightDelayTime: "10"
    };
    // 途径点
    @observable pathways = [
        // {
        //     "lat":"39.915978",
        //     "lng":"116.433631",
        //     "name":"梓峰大厦"
        // }
    ];
    // 终点地址
    @observable endAddr = {
        cityName: "",
        cityCode: "",
        address: "",
        name: "",
        lat: "",
        lon: "",
        poi: ""
    };

    // 起点已经经过验证
    @observable beginValidationed = false;

    // 终点已经经过验证
    @observable endValidationed = false;

    // base验证失败，强行用车理由
    @observable baseLimitationConfirm = {}

    // 包车
    @observable charteredBusType = 1; 

    // 缓存的城市
    @observable citys = [];

    // 用户主动取消订单,导致页面跳回预估价页面标记,此标记为真是,后退回下单首页,当清空下单主页之缓存
    @observable isUserCancelForward = false;

    // 用户主动取消,退回预估价页面,如果被取消单是替人叫车,则暂时把用车人信息存储于此
    @observable passenger = {};

    @observable isBegin = true;

    // 首页默认起终点参数
    @observable mainParameter = undefined;

    // 美团途径点是否展示
    @observable showPathway = undefined

    /**
     * 刷新代办任务数
     */
    @action.bound refreshBadgeCount = async () => {
        let { data } = await webService.order.getBadgeCount();
        if (data.code === 0) {
            runInAction(() => {
              this.badgeCount = data.data;
            })
        }
    }

    @computed get badgeSum() {
        let sum = this.badgeCount.runningOrderCount 
                + this.badgeCount.waitApproveOrderCount
                + this.badgeCount.waitPayOrderCount
                + this.badgeCount.waitPreApprovalCount
                + this.badgeCount.availableCouponCount;
        return sum
    }

    /**
     * 用车参数面板初始化
     */
    @action.bound init() {
        this.beginAddr = {
            cityName: "",
            cityCode: "",
            address: "",
            name: "",
            lat: "",
            lon: "",
            poi: "",
            departureTime: "",
            flightNumber: "",
            trainNumber:"",
            trainArr: "",
            trainDep: "",
            departTime: "",
            arrivalAirportCode: "",
            departAirportCode: "",
            // 不参与传参属性
            airTip: "",
            trainTip:"",
            arrivalTime: "",
            flightDelayTime: "10"
        };
        this.pathways = [];
        this.endAddr = {
            cityName: "",
            cityCode: "",
            address: "",
            name: "",
            lat: "",
            lon: "",
            poi: ""
        };
        this.beginValidationed = false;
        this.endValidationed = false;
        this.baseLimitationConfirm = {}
    }

    @action.bound clear() {
        this.init();
        this.serverType = 0;
        this.mapCenter = [];
        this.activeOrder = [];
        this.scene = {};
        this.preApply = {};
        this.isUserCancelForward = false;
        this.passenger = {};
        this.beginValidationed = false;
        this.endValidationed = false;
        this.baseLimitationConfirm = {};
    }

    /**
     * 判断起点终点参数是否完善
     * @returns 
     */
    @action.bound isComplete() {
        let completePathWay = this.pathways && (this.pathways.length === 0 || (this.pathways.length && this.pathways.every(a => a.lat)))
        return this.beginAddr.cityName && this.beginAddr.cityCode && this.beginAddr.name
                && this.beginAddr.lat && this.beginAddr.lon
                && this.endAddr.cityName && this.endAddr.cityCode && this.endAddr.name
                && completePathWay
                && this.endAddr.lat && this.endAddr.lon;
    }

    /**
     * 设置地图中心点
     * @param {*} mapCenter 
     */
    @action.bound setMapCenter(mapCenter) {
        this.mapCenter = mapCenter
    }

    /**
     * 设置当前定位地址
     * @param {*} currentAddr 
     */
     @action.bound setCurrentAddr(currentAddr) {
        this.currentAddr = currentAddr
    }

    /**
     * 判断使用吸附地点或当前地点
     * @param {*} value 
     */
    @action.bound setUsePlace(bool) {
      this.isUsePlace = bool
    } 

    /**
     * 设置活动订单等信息
     * @param {*} activeOrder 
     */
    @action.bound setActivityInfo(activeOrder) {
        this.activeOrder = activeOrder;
    }

    /**
     * 设置默认用车场景
     * @param {*} scene 
     */
     @action.bound setDefaultScene(scene) {
        let needSetDefaultPreApply = this.scene.id !== scene.id
        if (!this.beginAddr.lat) {
            this.scene = scene;
            // 如果改了场景，需要重设
            if (needSetDefaultPreApply) {
                this.setDefaultPreApply(scene)
            }
        }
    }

    /**
     * 设置当前城市信息
     * @param {*} city 
     */
    @action.bound setCurrentCity(city) {

        if (this.beginAddr.cityCode !== city.cityCode && gc.process.isMeituan && globalStore.userInfo.permissions && globalStore.userInfo.permissions.main_pathwayPoint) {
          this.setShowPathway(city.cityCode)
        }

        this.beginAddr = {
            ...this.beginAddr,
            address: city.address,
            cityCode: city.cityCode,
            cityName: city.cityName
        };
        // if (!gc.process.showOrderButton) {
        //     this.endAddr = {
        //         ...this.endAddr,
        //         cityCode: city.cityCode,
        //         cityName: city.cityName
        //     }
        // }
    }

    /**
     * 只设置当前城市信息
     * @param {*} city 
     */
    @action.bound setCurrentCityOnly(city) {
        this.beginAddr = {
            cityCode: city.cityCode,
            cityName: city.cityName
        };

        if (gc.process.isMeituan && globalStore.userInfo.permissions && globalStore.userInfo.permissions.main_pathwayPoint) {
          this.setShowPathway(city.cityCode)
        }
    }

    /**
     * 设置地图中心最近上车地点
     * @param {*} item 
     */
    @action.bound setParking(item) {
        if (item.isFinishEnd && !this.isBegin) {
          this.endAddr = {
            ...this.endAddr,
            address: item.address,
            name: item.name,
            lon: item.lon,
            lat: item.lat,
            poi: item.poi,
          }
          this.endValidationed = false
          this.baseLimitationConfirm = {}
        } else {
          this.beginAddr = {
              ...this.beginAddr,
              name: item.name,
              lon: item.lon,
              lat: item.lat,
              poi: item.poi
          };
          this.beginValidationed = false
        }
    }
    /**
     * 移动清空起点 name 与 address
     * @param {*} item 
     */
    @action.bound setNameAddrEmpty(item) {
      this.beginAddr = {
        ...this.beginAddr,
        name: item.name,
        address: item.address
      };
      this.beginValidationed = false
    }

    /**
     * 切换服务类型
     * @param {*} serverType 
     */
    @action.bound chooseServerType(serverType) {
        this.serverType = serverType;
        this.beginAddr.departureTime = ''
        this.beginAddr.airTip = ''
        this.beginAddr.trainTip = ''
        this.beginAddr.flightNumber = ''
        this.beginAddr.trainNumber = ''
        this.beginAddr.trainArr = ''
        this.beginAddr.trainDep = ''
        this.setPreApply('')
        this.setIsBegin(true)
        let item = {
          cityCode: this.beginAddr.cityCode,
          cityName: this.beginAddr.cityName
        }
        if (!gc.process.showOrderButton) {
            // 无下单按钮的时候清空目的地，有下单按钮的时候保留目的地
            this.choosedEndAddr(item)
        }
        if (Number(serverType) === 6 || Number(serverType) === 20) {
            this.pathways = []
        }
        
    }

    /**
     * 设置选择的用车场景
     * @param {*} scene 
     */
    @action.bound setScene(scene) {
        let needSetDefaultPreApply = this.scene.id !== scene.id;
        this.scene = scene;
        // 如果改了场景，需要重设
        if (needSetDefaultPreApply) {
            this.setDefaultPreApply(scene)
        }
    }

    @action.bound async setDefaultPreApply(scene) {
        // 1.清空申请单
        this.preApply = {};
        // 2.加载默认申请单
        if (scene.needPreApproval) {
            let params = {
                scenePublishId: scene.publishId,

                pageNum: 1,
                pageSize: 10
            }
            let { data } = await webService.preApproval.queryUsableApplyList(params);
            if (data.code === 0 && data.data.rows && data.data.rows.length === 1 && this.scene.id === scene.id && !this.preApply.preDepartApplyId) {
                // 当前场景只有一个申请单，且当前无其它申请单被选中，则默认选择之
                this.preApply = {
                    preDepartApplyId: data.data.rows[0].preDepartApplyId
                }
            }
        }
    }

    @action.bound setPreApply(preApply) {
        this.preApply = preApply;
    }

    /**
     * 设置预约用车时间
     * @param {*} departureTime 
     */
    @action.bound setDepartureTime(departureTime) {
        console.log(departureTime)
        this.beginAddr.departureTime = departureTime;
    }

    /**
     * 手选设置用车起点
     * @param {*} item 
     */
    @action.bound choosedBeginAddr(item) {

        if (this.beginAddr.cityCode !== item.cityCode && gc.process.isMeituan && globalStore.userInfo.permissions && globalStore.userInfo.permissions.main_pathwayPoint) {
          this.setShowPathway(item.cityCode)
        }

        this.beginAddr = {
            ...this.beginAddr,
            cityCode: item.cityCode,
            cityName: item.cityName,
            name: item.name,
            address: item.address,
            lon: item.lon,
            lat: item.lat,
            poi: item.poi
        };
        this.beginValidationed = false
        if (!gc.process.showOrderButton) {
            // 无下单按钮的时候清空目的地，有下单按钮的时候保留目的地
            // this.endAddr = {
            //     ...this.endAddr,
            //     cityCode: item.cityCode,
            //     cityName: item.cityName,
            //     name: '',
            //     address: '',
            //     lon: '',
            //     lat: '',
            //     poi: ''
            // };
            this.clearEnd()
            this.endValidationed = false
            this.baseLimitationConfirm = {}
        }
    }

    /**
     * 检索设置起点名称吸附功能添加
     * @param {*} item 
     */
     @action.bound choosedBeginAddrSearch(item) {
        this.beginAddr = {
            ...this.beginAddr,
            name: item.name,
            address: item.address,
            lon: item.lon,
            lat: item.lat,
            poi: ''
        };
        this.beginValidationed = false
    }

    /**
     * 只修改用车起点
     * @param {*} item 
     */
    @action.bound setBeginAddrOnly(item) {
        this.beginAddr = {
            ...this.beginAddr,
            cityCode: item.cityCode,
            cityName: item.cityName,
            name: item.name,
            address: item.address,
            lon: item.lon,
            lat: item.lat,
            poi: item.poi ? item.poi : ''
        };
        this.beginValidationed = false
    }

    /**
     * 增加一个途径点
     */
    @action.bound addPathways() {
        if (this.pathways.length < 2) {
            this.pathways.push({})
        }
    }

    /**
     * 设置途径点
     * @param {*} item 
     * @param {*} index 
     */
    @action.bound setPathways(item, index) {
        if (this.pathways[index]) {
            this.pathways[index].lat = item.lat
            this.pathways[index].lng = item.lon
            this.pathways[index].name = item.name
        }
    }

    @action.bound removePathways(index) {
        if (this.pathways[index]) {
            this.pathways.splice(index, 1)
        }
    }

    /**
     * 手选设置用车终点
     * @param {*} item 
     */
     @action.bound choosedEndAddr(item) {
        this.endAddr = {
            ...this.endAddr,
            cityCode: item.cityCode,
            cityName: item.cityName,
            name: item.name,
            address: item.address,
            lon: item.lon,
            lat: item.lat,
            poi: item.poi
        };
        this.endValidationed = false
        this.baseLimitationConfirm = {}
    }

    /**
     * 清空出发地
     */
    @action.bound clearBegin(name) {
        this.beginAddr = {
            // 测试要求清空出发地时候保留出发地城市
            cityName: this.beginAddr.cityName,
            cityCode: this.beginAddr.cityCode,
            address: "",
            name: name || "",
            lat: "",
            lon: "",
            poi: "",
            departureTime: "",
            flightNumber: "",
            trainNumber:"",
            trainArr:'',
            trainDep:'',
            flightDepartAirportCode: "",
            flightArrivalAirportCode: "",
            departTime: "",
            arrivalAirportCode: "",
            departAirportCode: "",
            // 不参与传参属性
            airTip: "",
            trainTip:"",
            arrivalTime: "",
            flightDelayTime: "10"
        };
        this.beginValidationed = false
    }

    /**
     * 清空目的地
     */
    @action.bound clearEnd() {
        this.endAddr = {
            cityName: "",
            cityCode: "",
            address: "",
            name: "",
            lat: "",
            lon: "",
            poi: ""
        };
        this.endValidationed = false
        this.baseLimitationConfirm = {}
    }

    /**
     * 设置用车起点验证标记
     * @param {*} value 
     */
    @action.bound setBeginValidationed(value) {
        this.beginValidationed = value
    }

    /**
     * 设置用车终点验证标记
     * @param {*} value 
     */
    @action.bound setEndValidationed(value) {
        this.endValidationed = value
    }

    /**
     * 设置base地验证不通过，强行用车确认信息
     * @param {*} value 
     */
    @action.bound setBaseLimitationConfirm(value) {
        this.baseLimitationConfirm = value
    }


    /**
     * 如果终点选择地址有围栏情况下，用户手动关闭弹窗后，起点终点都有值，默认把终点数据赋给起点，因为当前位置围栏覆盖范围
     * @param {*} ori 
     */
    @action.bound boundExchangeParameter(item) {
      if (this.endAddr.lon && this.endAddr.lon && this.endAddr.name) {
        this.beginAddr.cityCode = this.endAddr.cityCode;
        this.beginAddr.cityName = this.endAddr.cityName;
        this.beginAddr.name = this.endAddr.name;
        this.beginAddr.address =  this.endAddr.address;
        this.beginAddr.lon =  this.endAddr.lon;
        this.beginAddr.lat = this.endAddr.lat;
        this.beginAddr.poi = this.endAddr.poi;
        this.beginValidationed = false
      }
    }

    /**
     * 手选设置航班
     * @param {*} ori 
     */
     @action.bound choosedFlight(ori) {
        let arrivalAirportCoord = ori.arrivalAirportCoord.split(',');
        this.beginAddr = {
            ...this.beginAddr,
            flightNumber: ori.flightNumber,
            airTip: ori.tip,
            // 预约用车时间,是飞机计划到达时间
            departureTime: ori.planArrivalTime,
            // 此为飞机起飞时间
            departTime: ori.planDepartTime,
            // 飞机到达时间,在飞机实际到达之前都是计划到达时间,而实际到达以后的航班无法接机,因此这里永远用计划到达时间
            arrivalTime: ori.planArrivalTime,
            departAirportCode: ori.departAirportCode,
            arrivalAirportCode: ori.arrivalAirportCode,
            lon: arrivalAirportCoord[0],
            lat: arrivalAirportCoord[1],
            poi: ori.poi ? ori.poi : '',
            cityName: ori.arrivalCityName,
            cityCode: ori.arrivalCityCode,
            name: ori.arrivalAirportName,
            address: ori.arrivalAirportLocation
        };
        this.beginValidationed = false

        // this.endAddr = {
        //     ...this.endAddr,
        //     cityName: ori.arrivalCityName,
        //     cityCode: ori.arrivalCityCode,
        //     name: '',
        //     address: '',
        //     lon: '',
        //     lat: '',
        //     poi: ''
        // }
        this.clearEnd()
    }

    /**
     * 手选设置车次
     * @param {*} item 
     */
    @action.bound chooseTrain(item) {
        this.beginAddr = {
            ...this.beginAddr,
            trainNumber: item.trainNum,
            trainArr:item.trainArr,
            trainDep:item.trainDep,
            flightDepartAirportCode: item.trainDep,
            flightArrivalAirportCode: item.trainArr,
            trainTip: item.tip,
            // 预约用车时间,是列车计划到达时间
            departureTime: item.planArrivalTime,
            // 此为列车出发时间
            departTime: item.planArrivalTime,
            arrivalTime: item.planArrivalTime,
            lon: item.lng,
            lat: item.lat,
            poi: '',
            cityName: item.cityName,
            cityCode: item.cityCode,
            name: item.name,
            address: item.address
        };
        this.beginValidationed = false

        this.clearEnd()
    }

    /**
     * 设置城市缓存
     * @param {*} citys 
     */
    @action.bound setCitys(citys) {
        if (window.lang === 2) {
            // 当前是英文环境
            citys = citys.map(a => {
                return {
                    ...a,
                    cityName: a.cityPinYin
                }
            })
        }
        
        this.citys = citys;
    }

    /**
     * 设置首页起终点参数
     * @param {*} mainParameter 
     */
    @action.bound setMainParameter(mainParameter) {
        this.mainParameter = mainParameter;
    }

    /**
     * 复位下单参数
     * @param {*} data 
     */
    @action.bound reset(data, passenger) {
        this.mapCenter = data.mapCenter;
        this.serverType = data.serverType;
        this.scene = data.scene;
        this.preApply = data.preApply;
        this.beginAddr = data.beginAddr;
        this.beginValidationed = false
        this.pathways = data.pathways ? data.pathways : [];
        this.endAddr = data.endAddr;
        this.endValidationed = false
        this.isUserCancelForward = true;
        this.mainParameter = undefined;

        if (passenger) {
            this.passenger = passenger;
        }

        this.baseLimitationConfirm = data.baseLimitationConfirm;
    }

    @action.bound setIsBegin(bool) {
      this.isBegin = bool;
    }

    /**
     * 设置包车
     * @param {*} data 
     */
    @action.bound setCharteredBusType(type) {
      this.charteredBusType = type;
    }

    @action.bound async setShowPathway(code) {
      let {data} = await webService.basCity.supportPassingPoint({cityId: code})
      if (data.code === 0) {
        this.showPathway = data.data;
      }
    }
}

// eslint-disable-next-line
export default new MainStore();