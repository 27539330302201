import React from 'react'
import style from './confirm.scss';
import { inject, observer } from 'mobx-react'
import { InformationCircleOutline, StarFill, RightOutline, CloseOutline } from 'antd-mobile-icons'
import { List, Input, Checkbox, Button, Dialog, Ellipsis, TextArea, CascadePicker, Toast, Image, SearchBar, CheckList } from 'antd-mobile'

import AppRouter from './../component/router/index'

import Coupon from './../component/callcar/coupon'
import webService from '../util/webService';
import LoadingMask from './../component/utils/loadingMask';
import { generateConfirmDependency, generatePlaceOrder } from './../util/paramsHelper'

import { stringToBase64, checkPhoneNumber } from './../util/utils'
import { ES_SuiXingWeiShi, ES_JieJiLiBin } from './../util/config'

import CustomForm from '../component/callcar/customForm';
import JinDuCarInformation from '../component/callcar/jinDuCarInformation' 

import { debounceUpgrades } from './../util/utils';

import { serverBaseUrl } from './../util/config'

import { axios } from './../util/webService'

import { withTranslation } from "react-i18next";
import images from '../util/images';
import { ForgeRouter } from '../util/utils'
import { Popup } from 'antd-mobile'
import UserAddreessBook from '../component/callcar/userAddreessBook'
import gc from '../util/globalConfiguration'
import AddSanofiMeeting from './special/addSanofiMeeting';
import MessageBox from '../component/callcar/messageBox'

@inject('mainStore', 'estimateStore', 'globalStore', 'confirmStore')
@observer
class Confirm extends React.Component {

  state = {
    agreement: false,
    showProjects: false,
    seachProjectKey: '',
    isShowMask: true,
    readOnly: false,
    customForm: {
    },
    popoverVisible: true,
    isGreenPop: null,
    showAddreessBook: false,
    users: [],
    showSearchProjectNum: false,
    showReplaceCar: false,
    filteredItems: [],
    searchText: '',
    showSanofiMeeting: false,
    alternatePhone: '',
    alternatePhoneName: '',
    isplaceOrder: false
  };
  mainStore = this.props.mainStore;
  estimateStore = this.props.estimateStore;
  globalStore = this.props.globalStore;
  confirmStore = this.props.confirmStore;

  messageBoxRef = undefined;

  t = this.props.t

  formRef = React.createRef()


  get isAgreement() {
    if (this.globalStore.userInfo.isAgreement) {
      return true
    }
    return false
  }

  get isShowGreen() {
    return this.state.isGreenPop !== null && this.state.isGreenPop && this.confirmStore.dependency.showLowCarbon
  }

  // 用户代叫车权限
  get isShowPhone() {
    if (this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.order_editPhone) {
      if (gc.process.isAZ) {
        if (this.mainStore.scene.code === '10004') {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    }
    return false
  }

  // 海目星一期需求开关
  get isProjectNumber() {
    if (this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.confirm_project_number) {
      return true
    }
    return false
  }

  get isLanguage() {
    return this.globalStore.userInfo.language === 1 ? true : false
  }

  // sanofi企业下场景是否选择了会议用车
  get isSelectSanofiMeetScen() {
    console.log("this.mainStore.scene?.code =", this.mainStore.scene)
    return this.mainStore.scene?.code === "QUKDI1LG" ? true : false
  }

  // 默克是否展示乘车人姓名
  get isConfirmPassengerName() {
    if (this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.confirm_passenger_name) {
      return true
    }
    return false
  }

  // 是否隐藏乘车人电话
  get isConfirmHidePassengerPhone() {
    if (this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.confirm_hide_passenger_phone) {
      return true
    }
    return false
  }
  
  // 判断按钮
  get isDissatisfied() {
    if (this.confirmStore.dissatisfied) {
      return true
    }else if(this.isConfirmPassengerName && !this.confirmStore.passenger.name) {
      return true
    } else if(!this.globalStore.userInfo.isAgreement) {
      return true
    } else if (this.state.isplaceOrder) {
      return true
    }
    return false
  }

  get isJindu() {
    if (gc.process.isJindu && this.mainStore.scene.code !== 'UMD1TWNH') {
      return true
    }
    return false
  }

  get checkES_SuiXingWeiShi() {
    let res = this.confirmStore.dependency?.extraServices?.filter((item) => item?.code === ES_SuiXingWeiShi)
    if (res.length > 0 && res[0].checked && !this.globalStore.userInfo.emergencyPhone) {
      return true
    }
    return false
  }

  get beginAddrName() {
    try {
      if (this.mainStore.beginAddr.name) {
        let arr = this.mainStore.beginAddr.name.split('　')
        if (arr && arr.length) {
          return arr.reverse().join(' ')
        }
      }
      
    } catch (exp) {
      console.error(exp)
    }

    return this.mainStore.beginAddr.name
  }

  get endAddrName() {
    try {
      if (this.mainStore.endAddr.name) {
        let arr = this.mainStore.endAddr.name.split('　')
        if (arr && arr.length) {
          return arr.reverse().join(' ')
        }
      }
      
    } catch (exp) {
        console.error(exp)
    }
    return this.mainStore.endAddr.name
  }

  // 是否显示乘车人电话
  get passengerPhone() {
    if(this.confirmStore.passenger.flagPhone) {
      return this.confirmStore.passenger.phone
    }
    return ''
  }

  /**
   * 渲染增值服务
   */
  renderExtraServices = () => {
    if (this.confirmStore.dependency.extraServices && this.confirmStore.dependency.extraServices.length) {
      return <div className={`${style.block} ${style.added}`}>
        <div className={style.head}>{this.t('confirm.k001')}</div>
        {
          this.confirmStore.dependency.extraServices.map(item => {
            return <div className={style.item} key={item.id}>
              <div>
                <div>{this.isLanguage ? item.nameCn : item.nameEn} <InformationCircleOutline color="var(--adm-color-primary)" onClick={() => {
                  Dialog.alert({
                    content: this.isLanguage ? item.discriptionCn : item.discriptionEn,
                    confirmText: this.t('common.k003')
                  })
                }}></InformationCircleOutline> </div>
                <span>{this.isLanguage ? item.outlineCn : item.outlineEn}</span>
              </div>
              <div>￥{item.price}/{this.t('confirm.k002')}</div>
              <div>
                <Checkbox checked={item.checked} onChange={(v) => { this.checkExtraServices(item, v); }}></Checkbox>
              </div>
            </div>
          })
        }
      </div>
    }
  }

  /**
   * 渲染用车说明
   */
  renderUseReason = () => {
    if (this.confirmStore.dependency.scene.usedReasonMode && this.confirmStore.dependency.scene.usedReasonMode !== 1) {
      return <div className={`${style.block} ${style.added}`}>
        <div className={style.head}>{this.t('confirm.k003')}{this.confirmStore.dependency.scene.usedReasonMode === 3 ? this.t('confirm.k004') : ''}</div>
        <div>
          <TextArea placeholder={this.confirmStore.dependency.scene.usedReasonTips ? this.confirmStore.dependency.scene.usedReasonTips : ''} maxLength={100} rows={3} value={this.confirmStore.dependency.scene.usedReason} onChange={(val) => this.confirmStore.setUsedReason(val)} />
        </div>
      </div>
    }
  }

  /**
   * 渲染可用卡券数量
   */
  renderCouponCount = () => {
    let validCount = this.confirmStore.dependency.coupons.filter(a => a.isValid === true).length;
    if (validCount > 0) {
      return <div style={{ color: '#F5A623' }} onClick={() => {
        this.props.history.push('/chooseCoupon');
      }}>{this.t('confirm.k005', { validCount: validCount })}</div>
    } else {
      return <div style={{ color: '#B0B5BC' }} onClick={() => {
        this.props.history.push('/chooseCoupon');
      }}>{this.t('confirm.k006')}</div>
    }

  }

  /**
   * 渲染卡券
   */
  renderCoupons = () => {
    if (this.confirmStore.coupon) {
      return <Coupon t={this.props.t} data={this.confirmStore.coupon} onClick={() => {
        this.props.history.push('/chooseCoupon');
      }} />
    } else {
      return <div className={style.block}>
        <List style={{ '--prefix-width': '6em' }}>
          <List.Item prefix={this.t('confirm.k007')} arrow extra={this.renderCouponCount()} >
          </List.Item>
        </List>
      </div>
    }

  }

  /**
   * 渲染代叫车
   */
  renderPassengerPhone = () => {
    if (this.isShowPhone) {
      return <div className={style.replace_box}>
        <div className={style.content} onClick={() => {
          this.setState({
            showReplaceCar: true
          })
        }}>
          <div className={style.left}>{this.t('confirm.k070')}</div>
          <div className={style.right}>
            <span>{this.passengerPhone}</span>
            <RightOutline/>
          </div>
        </div>
        <Popup
          position='bottom'
          bodyStyle={{ height: '42%'}}
          getContainer={() => document.getElementsByClassName('_2kvh0iCWvvQjDq-dnC-a1t')[0]}
          visible={this.state.showReplaceCar}
          onMaskClick={() => {
            this.setState({
              showReplaceCar: false
            })
          }}
          destroyOnClose
        >
          <div className={style.service_car}>
            <div className={style.title}>
              <div>{this.t('confirm.k071')}</div>
              <CloseOutline fontSize={18} onClick={() => {
                this.setState({
                  showReplaceCar: false
                })
              }}/>
            </div>
            <div className={style.card}>
              <List mode='card' style={{ '--prefix-width': '6em' }}>
                <List.Item className={style.passenger_box}>
                  <Input type='number' placeholder={this.t('confirm.k072')} clearable={false} className={style.phone} value={this.state.friendPhone} onChange={
                  (val) => {
                    this.setState({
                      friendPhone: val
                    })
                  }} />
                  {
                    this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.setting_useAddressBook
                      ? <div className={style.addressImg} onClick={this.handleAddressBook}>
                        <Image
                          src={images.addressBook}
                          width={19}
                          height={19}
                          fit='cover'
                        />
                        {/* 通讯录 */}
                        <span>{this.t('confirm.k048')}</span>
                      </div>
                      : <></>
                  }
                </List.Item>
                <List.Item>
                  <Input placeholder={this.t('confirm.k073')} clearable={false} className={style.phone} value={this.state.friendName} onChange={
                    (val) => {
                      this.setState({
                        friendName: val
                      })
                    }} />
                </List.Item>
              </List>
              {
              this.state.friendPhone
                  ? <Button className={style.confirm_btn} disabled={false} block style={
                    {
                      'background-image': 'var(--button_background_color)',
                      '--border-color': 'var(--button_border_color)',
                      'background-color': 'var(--button_border_color)',
                      'color': 'var(--button_color)'
                    }
                  } size='middle' onClick={() => {
                    // 是否设置过代叫车手机号
                    this.confirmStore.setShowPhone(true);
                    // 设置过代叫车手机号
                    this.confirmStore.setPassengerPhone(this.state.friendPhone)
                    if (this.state.friendName) {
                      // 是否设置过代叫车姓名
                      this.confirmStore.setFlagName(true)
                      // 设置过代叫车姓名
                      this.confirmStore.setPassengerName(this.state.friendName)
                    }
                    this.setState({
                      showReplaceCar: false
                    })
                  }}>{this.t('confirm.k074')}</Button>
                  : <Button className={style.confirm_btn} block style={
                    {
                      'background-image': 'var(--button_background_color)',
                      '--border-color': 'var(--button_border_color)',
                      'background-color': 'var(--button_border_color)',
                      'color': 'var(--button_color)'
                    }
                  } size='middle' disabled>{this.t('confirm.k074')}</Button>
              }
              <Button className={style.cancel_btn} block size='middle' onClick={() => {
                this.confirmStore.setPassengerPhone(this.globalStore.userInfo.phone)
                this.confirmStore.setPassengerName(this.globalStore.userInfo.nameCn)
                this.confirmStore.setShowPhone(false)
                this.confirmStore.setFlagName(false)
                this.setState({
                  friendPhone: '',
                  friendName: '',
                  showReplaceCar: false
                })
              }}>{this.t('confirm.k075')}</Button>
            </div>
          </div>
        </Popup>
      </div>
    }
  }

  render() {
    return <div className={style.container}>
      <MessageBox ref={(dom) => { this.messageBoxRef = dom; }} history={this.props.history}></MessageBox>
      <LoadingMask show={this.state.isShowMask} />
      <div className={style.block}>
        <List mode='card' style={{ '--prefix-width': '6em' }}>
          <List.Item prefix={this.t('confirm.k008')} extra={<div className={style.ellipsis}>{this.beginAddrName}</div>}>
          </List.Item>
          <List.Item prefix={this.t('confirm.k009')} extra={<div className={style.ellipsis}>{this.endAddrName}</div>}>
          </List.Item>
        </List>
      </div>

      <div className={style.block}>
        <List mode='card' style={{ '--prefix-width': '6em' }}>
          {
            // !this.isConfirmHidePassengerPhone ? 
            // <List.Item className={style.addressBook} prefix={this.t('confirm.k010')} arrow={false}>
            //   <Input placeholder={this.t('confirm.k011')}  clearable={false} disabled={!this.isReadWrite} className={style.phone} value={this.confirmStore.passenger.phone} onChange={
            //     (val) => {
            //       this.confirmStore.setPassengerPhone(val);
            //     }} />
            //   {
            //     this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.setting_useAddressBook
            //       ? <div className={style.addressImg} onClick={this.handleAddressBook}>
            //         <Image
            //           src={images.addressBook}
            //           width={19}
            //           height={19}
            //           fit='cover'
            //         />
            //         {/* 通讯录 */}
            //         <span>{this.t('confirm.k048')}</span>
            //       </div>
            //       : <></>
            //   }

            // </List.Item> : ''
          }
          {
            (() => {
              if (this.isConfirmPassengerName) {
                return(
                  <List.Item className={style.addressBook} prefix={this.t('confirm.k059')} arrow={false}>
                    <Input placeholder={this.t('confirm.k060')} clearable={false} className={style.phone} value={this.confirmStore.passenger.name} onChange={
                      (val) => {
                        this.confirmStore.setPassengerName(val);
                      }} />
                  </List.Item>
                )
              }
            })()
          }
          {
            (() => {
              if (this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.main_scene) {
                return <List.Item className={style.scene} prefix={this.t('confirm.k012')} extra={this.mainStore.scene.nameCn}>
                </List.Item>
              }
            })()
          }
          {
            (() => {
              if (this.mainStore.preApply && this.mainStore.preApply.preDepartApplyId) {
                return <List.Item prefix={this.t('confirm.k039')} extra={this.mainStore.preApply.preDepartApplyId}>
                </List.Item>
              }
            })()
          }
          {
            (() => {
              if (this.confirmStore.dependency.showProject && this.confirmStore.dependency.projects && this.confirmStore.dependency.projects.length > 0) {
                return (
                  this.confirmStore.dependency.projects && this.confirmStore.dependency.projects.length === 1
                    ? (<List.Item prefix={this.t('confirm.k013')} extra={
                      <div style={{ width: '200px', textAlign: 'right' }}>
                        <Ellipsis direction='end' content={`${this.confirmStore.project.nameCn}`} />
                      </div>
                    }>
                    </List.Item>)
                    : (<List.Item prefix={this.t('confirm.k013')} >
                      <Input placeholder={this.confirmStore.projectsTitle ? this.t('confirm.k014') : this.t('confirm.k015')} clearable={false} className={style.phone}
                        value={this.state.seachProjectKey}
                        readOnly={this.state.readOnly}
                        onChange={(val) => this.handleInput(val)}
                        onFocus={() => { this.setState({ showProjects: true }) }}
                        onBlur={() => {
                          setTimeout(() => {
                            // let project = this.confirmStore.project;

                            // if (project && project.nameCn) {
                            //     this.setState({
                            //         showProjects: false,
                            //         seachProjectKey: project.nameCn
                            //     });
                            // } else {
                            //     this.setState({
                            //         showProjects: false,
                            //         seachProjectKey: ''
                            //     });
                            // }
                            let { seachProjectKey } = this.state;
                            if (!seachProjectKey) {
                              seachProjectKey = '';
                            }
                            let a = this.confirmStore.dependency.projects
                              .filter(a => (a.nameCn && a.nameCn.indexOf(seachProjectKey) > -1)
                                || (a.nameEn && a.nameEn.indexOf(seachProjectKey) > -1)
                                || (a.code && a.code.indexOf(seachProjectKey) > -1))
                            if (a.length === 0) {
                              this.setState({ seachProjectKey: '' })
                              let params = {
                                code: '',
                                id: '',
                                nameCn: '',
                                nameEn: ''
                              }
                              this.confirmStore.setProject(params)
                            }
                            this.setState({
                              showProjects: false
                            });
                          }, 100);

                        }} />
                      {
                        (() => {
                          let { seachProjectKey } = this.state;
                          if (!seachProjectKey) {
                            seachProjectKey = '';
                          }
                          if (this.state.showProjects) {
                            return <div className={style.projects} v-show="showProjects">
                              {
                                this.confirmStore.dependency.projects
                                  .filter(a => (a.nameCn && a.nameCn.indexOf(seachProjectKey) > -1)
                                    || (a.nameEn && a.nameEn.indexOf(seachProjectKey) > -1)
                                    || (a.code && a.code.indexOf(seachProjectKey) > -1))
                                  .map((item, idx) => {
                                    return <div className={idx === this.confirmStore.dependency.projects.length - 1 ? '' : style.last} key={item.id} onClick={() => {
                                      this.setState({
                                        showProjects: false,
                                        seachProjectKey: item.nameCn
                                      })
                                      this.confirmStore.setProject(item)
                                    }}>
                                      {/* <Ellipsis direction='end' content={`[${item.code}] ${item.nameCn}`} /> */}
                                      <div>{this.isLanguage ? item.nameCn : item.nameEn}</div>
                                    </div>
                                  })
                              }
                            </div>
                          }
                        })()
                      }
                    </List.Item>)
                )
              }
            })()
          }
        </List>
      </div>

      {/* sanofi会议 */}
      {
        (() => {
            if(gc.process.isShowSanofiMeeting && this.isSelectSanofiMeetScen) {
                return <div className={style.block}>
                    <List mode='card' style={{ '--prefix-width': '6em' }}>
                        <List.Item prefix={this.t('title.k054')} extra={this.confirmStore.sanofiMeeting.eventCode} arrow={true} 
                            onClick={() => { this.setState({showSanofiMeeting: true}, () => {
                                let fr = new ForgeRouter(() => {
                                  let { hash } = document.location;
                                  if (hash === '#/confirm') {
                                    this.setState({
                                      showSanofiMeeting: false
                                    })
                                  }
                                });
                                fr.onpopstate();
                              }) }}>
                        </List.Item>
                    </List>
                </div>
            }
        })()
      }
      
      
      {/* 海目星需求项目编号 */}
      {
        this.isProjectNumber ? <div className={style.serial_number}>
        <div className={style.project_num}>
          {/* 项目编号 */}
            <div className={style.num}>{this.t('confirm.k053')}</div>
            {/* 请填入项目编号或无项目 */}
            {/* <Input placeholder={this.t('confirm.k054')} value={this.confirmStore.dependency.projectNum} clearable={false} onChange={
            (val) => {
              this.confirmStore.setProjectNum(val);
            }} /> */}
          </div>
          <div className={style.project_code}>{this.confirmStore.dependency.projectNum}</div>
          <div className={style.project_search} onClick={() => {
            this.setState({
              showSearchProjectNum: true
            })
          }}>
            <Image src={images.numberRetrieval} width={20} height={20} fit='cover' />
            {/* 编号检索 */}
            <span>{this.t('confirm.k055')}</span>
          </div>
        </div> : <></>
      }
      
      <Popup
        position='bottom'
        bodyStyle={{ height: '60%', overflowY: 'scroll'}}
        visible={this.state.showSearchProjectNum}
        onMaskClick={() => {
          this.setState({
            showSearchProjectNum: false
          })
        }}
        destroyOnClose
      >
        <div className={style.pop_title}>
          {/* 输入文字过滤选项 */}
          <SearchBar
            placeholder={this.t('confirm.k056')}
            value={this.state.searchText}
            onChange={v => {
              this.setState({
                searchText: v
              })
              this.getProjects(v)
            }}
          />
        </div>
        <div>
          <CheckList
            defaultValue={[]}
            onChange={val => {
              this.confirmStore.setProjectNum(val[0])
              this.setState({
                showSearchProjectNum: false
              })
            }}
          >
            {this.state.filteredItems.map(item => (
              <CheckList.Item key={item.id} value={item.code}>
                {item.code}
              </CheckList.Item>
            ))}
          </CheckList>
        </div>
      </Popup>


      <div className={style.carbon}>
        {
          this.isShowGreen ?
            <div className={style.prompt}>
              <div className={style.suggestion}>
                <img alt='' src={images.lowCarbon} />
              </div>
              <div className={style.content}>{this.t('confirm.k044')}</div>
              <div className={style.occupySpace}>
                <div>
                  <img alt='' src={images.leaf} />
                </div>
              </div>
              <div className={style.know} onClick={() => {
                this.setState({
                  isGreenPop: false
                })
              }}>{this.t('confirm.k045')}</div>
              <div className={style.triangle}></div>
            </div>
            : <></>
        }
        {/* 判断企业是否配置绿色出行 */}
        {
          this.confirmStore.dependency.showLowCarbon ? <div>
            <div className={style.title}>
              <StarFill color='#42a737' />
              <span>{this.t('confirm.k046')}</span>
            </div>
            <div className={style.carbonBar}>
              <div className={style.msg}>{this.t('confirm.k047')}</div>
              <Checkbox onChange={(e) => {
                this.confirmStore.setCheckLowCarbon(e)
              }} checked={this.confirmStore.dependency.checkLowCarbon} />
            </div>
          </div> : <></>
        }

      </div>


      <CustomForm t={this.props.t} ref={(dom) => this.formRef = dom} inputChange={(val, index, type) => {
        this.inputChange(val, index, type)
      }} radioChange={(val, index, type) => {
        this.radioChange(val, index, type)
      }} showCheckList={(val, index, type, id) => {
        this.showCheckList(val, index, type, id)
      }} closeCheckList={(val, index, type) => {
        this.closeCheckList(val, index, type)
      }} checkListChange={(type, val, index, id, special) => {
        this.checkListChange(type, val, index, id, special)
      }} textAreaChange={(val, index, type) => {
        this.textAreaChange(val, index, type)
      }} searchList={(val, index, type, id, dataSource) => {
        this.searchList(val, index, type, id, dataSource)
      }} custom={this.confirmStore.customCarList} />

      {/* 金杜成本中心 */}
      {
        this.isJindu ? <JinDuCarInformation t={this.props.t}></JinDuCarInformation> : <></>
      }

      {
        (() => {
          if (this.confirmStore.dependency.accounts.length > 1) {
            return <div className={style.block}>
              <List mode='card' style={{ '--prefix-width': '6em' }}>
                <List.Item prefix={this.t('confirm.k016')} arrow extra={this.confirmStore.account.name} onClick={async () => {
                  let options = this.confirmStore.dependency.accounts.map(a => {
                    return {
                      label: a.name,
                      value: a.id
                    }
                  });
                  let res = await CascadePicker.prompt({
                    options: options,
                    value: [this.confirmStore.account.id]
                  })
                  if (res && res.length) {
                    this.confirmStore.setAccount(res[0])
                  }
                }}>
                </List.Item>
              </List>
            </div>
          }
        })()
      }


      {this.renderExtraServices()}
      {this.renderUseReason()}
      {/* { this.renderCoupons() } */}

      {this.renderPassengerPhone()}

      <div className={style.agreement}>
        <Checkbox checked={this.globalStore.userInfo.isAgreement} onChange={(v) => {
          webService.user.setAgreement({ agreement: v });
          this.globalStore.setUserAgreement(v)
          this.confirmStore.setDissatisfied(!v)
          // this.setState({
          //     agreement: v
          // })
          // if (v) {
          //     if (!this.globalStore.userInfo.isAgreement) {
          //         webService.user.setAgreement({agreement: true});
          //     }
          //     this.globalStore.setUserAgreement(true);
          // }
        }} style={{
          '--icon-size': '12px',
          '--font-size': '12px'
        }}>{this.t('confirm.k017')}

        </Checkbox>
        {
          // (() => {
          //   let ua = dge()
          //   if (ua.indexOf('smartcomtrip') !== -1) {
          //     return <span onClick={() => { this.props.history.push('/confirm/tripAgreement') }}>{this.t('confirm.k019')}</span>
          //   }
          // })()
        }
        {
          gc.process.isCNPC ? <span onClick={() => { this.props.history.push('/confirm/tripAgreement') }}>{this.t('confirm.k019')}</span> : <></>
        }
        <span onClick={(e) => {
          e.stopPropagation()
          if (this.globalStore.userInfo.language === 1) {
            this.props.history.push('/confirm/agreement')
          } else {
            this.props.history.push('/confirm/agreementEn')
          }
        }}>{this.t('confirm.k040')}</span>

      </div>

      <div className={style.footbar}>

        {
          this.confirmStore.passenger.phone
            ? <Button disabled={this.isDissatisfied} block style={
              {
                'background-image': 'var(--button_background_color)',
                '--border-color': 'var(--button_border_color)',
                'background-color': 'var(--button_border_color)',
                'color': 'var(--button_color)'
              }
            } size='middle' onClick={debounceUpgrades(() => this.placeOrder(), 2000, true)}>{this.t('confirm.k020')}</Button>
            : <Button block style={
              {
                'background-image': 'var(--button_background_color)',
                '--border-color': 'var(--button_border_color)',
                'background-color': 'var(--button_border_color)',
                'color': 'var(--button_color)'
              }
            } size='middle' disabled>{this.t('confirm.k020')}</Button>
        }

        <Button block size='middle' onClick={this.reChoosecar}>{this.t('confirm.k021')}</Button>
        {/* <Button block size='large' onClick={() => {this.props.history.goBack()}}>重选车型</Button> */}
      </div>

      <AppRouter baseUri={this.props.router.path} routes={this.props.router.childrens} />

      <Popup
        position='bottom'
        visible={this.state.showAddreessBook}
        bodyStyle={{ height: '100%' }}
      >
        {/* 通讯录选择框 */}
        {this.state.showAddreessBook ? <UserAddreessBook uses={this.state.users} history={this.props.history} onChange={
          (a) => {
            this.confirmStore.setPassengerPhone(a);
            this.props.history.goBack();
          }

        }></UserAddreessBook> : ''}
      </Popup>

      <Popup
        position='bottom'
        visible={this.state.showSanofiMeeting}
        bodyStyle={{ height: '100%' }}>
        {/* sanofi会议选择框 */}
        {this.state.showSanofiMeeting ? <AddSanofiMeeting isMeeting={true}
        onChange={ (a) => { a ? this.confirmStore.setSanofiMeeting(a) : (() =>{})(); this.props.history.goBack(); }}>
        </AddSanofiMeeting> : ''}
      </Popup>
    </div>
  }

  async componentDidMount() {
    let { mainStore } = this;
    if (!mainStore.beginAddr.name) {
      this.props.history.goBack()
      return;
    }
    let { project, passenger } = this.confirmStore;
    this.setState({
      seachProjectKey: project.nameCn ? project.nameCn : '',
      // agreement: this.globalStore.userInfo.isAgreement ? true : false
    })

    if (!passenger.phone) {
      let isUserCancelForward = this.mainStore.isUserCancelForward;
      let brforPassenger = this.mainStore.passenger;
      if (isUserCancelForward && brforPassenger && brforPassenger.phone) {
        // 如果是用户主动取消订单后导航到第二部跳转而来,需考虑被取消订单使用的乘车人号码
        this.confirmStore.setPassenger({
          phone: brforPassenger.phone,
          name: brforPassenger.name
        })

      } else {
        this.getUserInfo()
      }
    }

    if (passenger.flagPhone) {
      this.setState({
        friendPhone: passenger.phone,
      })
    }

    if (passenger.flagName) {
      this.setState({
        friendName: passenger.name
      })
    }

    if (this.isProjectNumber) {
      // 获取海目星项目编号
      this.getProjects()
    }

    // this.confirmStore.setDissatisfied(!this.isAgreement)

    if (!this.confirmStore.dependency.existence) {
      let cep = generateConfirmDependency(this.mainStore.serverType, this.mainStore.scene, this.mainStore.beginAddr, this.mainStore.endAddr, this.estimateStore.choosedEstimates, this.mainStore.preApply, this.mainStore.charteredBusType);
      webService.order.getConfirmDependency(cep).then(d => {
        let { data } = d;

        if (data.code === 0) {
          data.data.existence = true;
          if (!data.data.projects) {
            data.data.projects = [];
          }
          if (!data.data.extraServices) {
            data.data.extraServices = [];
          }
          if (!data.data.coupons) {
            data.data.coupons = [];
          }

          if (data.data.projects.length === 0) {
            this.setState({
              readOnly: true
            })
          } else {
            this.setState({
              readOnly: false
            })
          }

          if (data.data.projects && data.data.projects.length === 1) {
            // 只有一个可选项目
            this.confirmStore.setProject(data.data.projects[0])
            let project = this.confirmStore.project;
            this.setState({
              seachProjectKey: project.nameCn,
            })
          }

          this.confirmStore.setDependency(data.data);
          if (this.confirmStore.dependency.projects && this.confirmStore.dependency.projects.length > 1) {
            this.confirmStore.setProjectsTitle(true)
          }

        }
        this.setState({
          isShowMask: false
        })
      });

      let params = {
        CompanyId: this.globalStore.companyInfo.id,
        SceneId: this.mainStore.scene.id
      }
      webService.order.getMobileCustomCarInfo(params).then(d => {
        let { data } = d;
        if (data.code === 0) {
          if (this.confirmStore.customCarList && this.confirmStore.customCarList.length === 0) {
            data.data.forEach(item => {
              item.initItems = item.items;
            });
            this.confirmStore.setCustomCarList(data.data)
          }
        }
      })

      // 是否展示绿色出行弹窗
      let { data } = await webService.order.getShowGreenTravel();
      if (data.code === 0) {
        this.setState({
          isGreenPop: data.data
        })
      }

    } else {
      this.setState({
        isShowMask: false
      })
    }

  }

  componentWillUnmount() {
    Dialog.clear()
  }

  /**
   * 勾选增值服务
   * @param {*} item 
   * @param {*} v 
   */
  checkExtraServices = (item, v) => {
    if (item.code === ES_SuiXingWeiShi) {
      // 随行卫士,需要存在紧急联系人
      if (!this.globalStore.userInfo.emergencyPhone) {
        // 缺少紧急联系人
        Dialog.show({
          title: this.t('confirm.k022'),
          content: this.t('confirm.k023'),
          actions: [[
            { key: 'cancel', text: this.t('confirm.k024') },
            {
              key: 'go_set', text: this.t('confirm.k025'), bold: true,
              onClick: () => {
                this.props.history.push('/settings')
              }
            }
          ]],
          closeOnAction: true
        })
        return;
      }
    }


    this.confirmStore.checkExtraServices(item, v);
  }

  /**
   * 确认叫车
   */
  placeOrder = async () => {
    let { serverType, scene, beginAddr, endAddr } = this.mainStore;
    let { choosedEstimates } = this.estimateStore;
    let { passenger, project, coupon, dependency, account, sanofiMeeting, jinDuInfo } = this.confirmStore;

    // 判断能否下单
    // 用车手机号
    if (!checkPhoneNumber(this.confirmStore.passenger.phone)) {
      Dialog.confirm({
        content: this.t('confirm.k026'),
        cancelText: this.t('common.k001'),
        confirmText: this.t('common.k002')
      })
      return;
    }

    // 用车原因
    if (this.confirmStore.dependency.scene.usedReasonMode === 2) {
      // 必填
      if (!this.confirmStore.dependency.scene.usedReason) {
        Dialog.confirm({
          content: this.t('confirm.k027'),
          cancelText: this.t('common.k001'),
          confirmText: this.t('common.k002')
        })
        return;
      }
    }

    if (this.checkES_SuiXingWeiShi) {
      // 缺少紧急联系人
      Dialog.show({
        title: this.t('confirm.k022'),
        content: this.t('confirm.k023'),
        actions: [[
          { key: 'cancel', text: this.t('confirm.k024') },
          {
            key: 'go_set', text: this.t('confirm.k025'), bold: true,
            onClick: () => {
              this.props.history.push('/settings')
            }
          }
        ]],
        closeOnAction: true
      })
      return;
    }

    // 同意协议
    if (!this.globalStore.userInfo.isAgreement) {
      Dialog.confirm({
        content: this.t('confirm.k028'),
        cancelText: this.t('common.k001'),
        confirmText: this.t('common.k002')
      })
      return;
    }

    if (this.isProjectNumber && !this.confirmStore.dependency.projectNum) {
      Dialog.confirm({
        content: this.t('confirm.k052'),
        cancelText: this.t('common.k001'),
        confirmText: this.t('common.k002')
      })
      return;
    }
    // sanofi企业下，场景为会议用车时，会议为必填项
    if (this.isSelectSanofiMeetScen && !sanofiMeeting?.eventCode) {
      Dialog.confirm({
        // 请选择会议
        content: this.t('confirm.k058'),
        cancelText: this.t('common.k001'),
        confirmText: this.t('common.k002')
      })
      return;
    }

    // 金杜成本中心校验
    if (this.isJindu && !jinDuInfo.costCenterCode) {
      Dialog.confirm({
        content: this.t('confirm.k062'),
        cancelText: this.t('common.k001'),
        confirmText: this.t('common.k002')
      })
      return;
    }

    // 金杜审批人检验
    if (this.isJindu && !jinDuInfo.staffCode) {
      Dialog.confirm({
        content: this.t('confirm.k064'),
        cancelText: this.t('common.k001'),
        confirmText: this.t('common.k002')
      })
      return;
    }

    // 金杜案件校验
    if (this.isJindu && !jinDuInfo.caseCode && jinDuInfo.showCaseItem) {
      Dialog.confirm({
        content: this.t('confirm.k066'),
        cancelText: this.t('common.k001'),
        confirmText: this.t('common.k002')
      })
      return;
    }

    let cacheOrderParams = {
      mainStore: {
        mapCenter: this.mainStore.mapCenter,
        serverType: this.mainStore.serverType,
        scene: this.mainStore.scene,
        preApply: this.mainStore.preApply,
        beginAddr: this.mainStore.beginAddr,
        pathways: this.mainStore.pathways,
        endAddr: this.mainStore.endAddr,
        baseLimitationConfirm: this.mainStore.baseLimitationConfirm,
      },
      estimateStore: {
        choosedEstimateCarLevel: this.estimateStore.choosedEstimateCarLevel,
        choosedEstimates: this.estimateStore.choosedEstimates.map(a => {
          // return {
          //   carSource: a.carSource,
          //   carSourceId: a.carSourceId,
          //   carLevel: a.carLevel
          // }
          return [a.carSource, a.carSourceId, a.carLevel, a.labelCode, a.subCarType].join(',')
        })
      },
      confirmStore: {
        passenger: this.confirmStore.passenger
      }
    }
    let order_params = stringToBase64(JSON.stringify(cacheOrderParams))

    let cep = generatePlaceOrder(serverType, scene, beginAddr, endAddr,
      choosedEstimates,
      passenger, project, coupon, dependency.extraServices, dependency.scene, this.estimateStore.estimateId,
      account.id, this.confirmStore.customCarList, this.mainStore.preApply, dependency.checkLowCarbon, this.mainStore.charteredBusType, this.estimateStore.carMode, this.mainStore.pathways, this.confirmStore.dependency.projectNum,
      this.mainStore.baseLimitationConfirm, sanofiMeeting, jinDuInfo);
    cep.orderParams = order_params;

    if (this.verifyCustom(cep.extraParameter.customInfo.customCarInfo)) {
      Toast.show({
        icon: 'loading',
        content: this.t('confirm.k029'),
        duration: 0,
        maskClickable: false
      })
      this.setState({
        isplaceOrder: true
      })
      let { data } = await webService.order.placeOrder(cep);
      //  let data = {
      //       code: 15654,
      //       message: '异常信息',
      //       data: {
      //               forwardUrl: 'https://www.baidu.com',
      //               fprwardBtnText: '美团登录错误'
      //       }
      //     }
      Toast.clear();
      this.placeOrderResp(data, cep);
    }
  }

  updateUserLowCarbon = async () => {
    // 绿色出行状态变更记录用户是否取消或选中默认选中
    let params = {
      userId: this.globalStore.userInfo.id,
      flag: this.confirmStore.dependency.checkLowCarbon
    }
    let { data } = await webService.order.updateUserLowCarbon(params)
    console.log(data)
  }

  placeOrderResp = async (data, cep) => {
    // 需要预付费
    if (data.code === 0 && data.data.prePayFlag) {
      localStorage.setItem('transaction_no', '');
      localStorage.setItem('prepaidInfo', JSON.stringify(data.data));
      this.updateUserLowCarbon()
      this.props.history.push('/prepaid')
    } else if (data.code === 0 && data.data.orderId) {
      this.updateUserLowCarbon()
      let isAirportPickup = this.confirmStore.isCheckedExtraServicesForCode(ES_JieJiLiBin);

      // 下单成功
      this.mainStore.clear();
      this.estimateStore.clear();
      this.confirmStore.clear();

      if (isAirportPickup) {
        // 选择了接机礼宾服务,跳转到等待神州接单页面
        this.props.history.push('/waitingShenzhou/' + data.data.orderId)
      } else {
        // 跳转到等待接单页面
        this.props.history.replace('/runing/' + data.data.orderId)
      }


    } else if (data.code === 20001) {
      Dialog.confirm({
        content: data.message,
        cancelText: this.t('common.k001'),
        confirmText: this.t('common.k002'),
        onConfirm: async () => {
          cep.confirm = true;
          let { data } = await webService.order.placeOrder(cep)
          this.placeOrderResp(data, cep)
        }
      })
    }
    else if (data.code === -21001) {
      Dialog.show({
        content: data.message,
        actions: [[
          {
            key: 'go_set', text: data.data ? <div className={style.go_confirm_pay}>{this.t('confirm.k068')}</div> : <div className={style.know}>{this.t('confirm.k069')}</div>, bold: true,
            onClick: () => {
              if (data.data) {
                this.props.history.replace('/orderdetails/' + data.data)
              }
            }
          }
        ]],
        closeOnAction: true
      })
    } 
    else if (data.code === 20002 || data.message.indexOf('20002') !== -1) {
      Dialog.alert({
        content: this.t('confirm.k030'),
        confirmText: this.t('common.k003'),
        onConfirm: async () => {
          // 重选车型
          this.reChoosecar();
        }
      })
    } else {
      if (data.data && data.data.forwardUrl) {
          Dialog.show({
            title: this.t('common.k004'),
            content: data.data.fprwardBtnText,
            actions: [
              [
                {
                  key: 'go_set', text: <div className={style.btn_details}>{this.t('common.k006')}</div>,bold: true, 
                  onClick: () => {
                    this.showMessageBox(data.data.forwardUrl)
                  }
                },
                {
                  key: 'cancel', text: <div className={style.btn_close}>{this.t('common.k005')}</div>, onClick: () => {
                    this.props.history.go(-1)
                  }
                }
              ]
            ],
            closeOnAction: true
          })
      } else {
        Dialog.show({
          content: data.message,
          actions: [[
            {
              key: 'go_set', text: <div className={style.yes_confirm_btn}>{this.t('common.k002')}</div>, bold: true,
              onClick: () => {
                // 重选车型
                if (data.message.indexOf('预估结果已过期') !== -1) {
                  this.reChoosecar();
                }
              }
            }
          ]],
          closeOnAction: true
        })
      }
    }
    this.setState({
      isplaceOrder: false
    })
  }

  /**
   * 重选车型
   */
  reChoosecar = () => {
    // 干掉所有历史记录,重新放入正确的历史栈
    this.props.history.goBack();
    // 以下代码ios不支持
    // this.props.history.replace('/'); 
    // this.props.history.push('/estimate');
  }

  // 获取 input value
  inputChange = (val, index, type) => {
    this.confirmStore.setCustomCarAddList(val, index, type, '', '')
  }

  radioChange = (val, index, type) => {
    this.confirmStore.setCustomCarAddList(val, index, type, '', '')
  }

  showCheckList = (val, index, type, id) => {
    this.confirmStore.setCustomCarAddList(val, index, type, id, '')
  }

  searchList = (val, index, type, id, dataSource) => {
    if (dataSource) {
      // 适配裁决号等类似企业
      axios.post(serverBaseUrl + dataSource, { key: val }).then( res => {
        if (res.data.code === 0) {
          this.confirmStore.setCustomSearchList(res.data.data, id)
          // this.confirmStore.setCustomCarAddList(val, index, type, id)
        }
      })
    } else {
      this.confirmStore.setCustomCarAddList(val, index, type, id, '')
    }
  }

  closeCheckList = (val, index, type) => {
    this.confirmStore.setCustomCarAddList(val, index, type, '', '')
  }

  checkListChange = (val, index, type, id, special) => {
    this.confirmStore.setCustomCarAddList(val, index, type, id, special)
  }

  textAreaChange = (val, index, type) => {
    this.confirmStore.setCustomCarAddList(val, index, type, '', '')
  }

  verifyCustom(customCarInfo) {
    if (customCarInfo.length <= 0) {
      return true
    }
    //eslint-disable-next-line
    let RegEmail = /^([A-Za-z0-9_\-\.\u4e00-\u9fa5])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,8})$/ //邮箱格式
    if (customCarInfo.length > 0) {
      let falg = true;
      for (let i = 0; i < customCarInfo.length; i++) {
        if (customCarInfo[i].type === 'phone' && customCarInfo[i].required) {
          if (customCarInfo[i].value === '') {
            Toast.show({
              content: `${this.t('confirm.k041')}${customCarInfo[i].name}`
            })
            falg = false;
            break;
          }
          if (!checkPhoneNumber(customCarInfo[i].value)) {
            Toast.show({
              content: `${this.t('confirm.k042')}${customCarInfo[i].name}`
            })
            falg = false;
            break;
          }
        } else if (customCarInfo[i].type === 'number' && customCarInfo[i].required) {
          if (customCarInfo[i].value === '') {
            Toast.show({
              content: `${this.t('confirm.k041')}${customCarInfo[i].name}`
            })
            falg = false;
            break;
          }
        } else if (customCarInfo[i].type === 'email' && customCarInfo[i].required) {
          if (customCarInfo[i].value === '') {
            Toast.show({
              content: `${this.t('confirm.k041')}${customCarInfo[i].name}`
            })
            falg = false;
            break;
          }
          if (!RegEmail.test(customCarInfo[i].value)) {
            Toast.show({
              content: `${this.t('confirm.k042')}${customCarInfo[i].name}`
            })
            falg = false;
            break;
          }
        } else if (customCarInfo[i].type === 'textArea' && customCarInfo[i].required) {
          if (customCarInfo[i].value === '') {
            Toast.show({
              content: `${this.t('confirm.k042')}${customCarInfo[i].name}`
            })
            falg = false;
            break;
          }
        }
        else if (customCarInfo[i].type === 'radio' && customCarInfo[i].required) {
          if (customCarInfo[i].value === '') {
            Toast.show({
              content: `${this.t('confirm.k043')}${customCarInfo[i].name}`
            })
            falg = false;
            break;
          }
        } else if (customCarInfo[i].type === 'checkList' && customCarInfo[i].required) {
          if (customCarInfo[i].value === '') {
            Toast.show({
              content: `${this.t('confirm.k043')}${customCarInfo[i].name}`
            })
            falg = false;
            break;
          }
        }
      }
      return falg
    }
  }

  getUserInfo = async () => {
    let d = await webService.basCity.getUserInfo();
    let { data } = d;
    if (data.code === 0) {
      this.confirmStore.setPassenger({
        phone: data.data.phone,
        name: data.data.nameCn
      })
      this.globalStore.setUserAgreement(data.data.isAgreement)
    }
  }

  handleInput = (val) => {
    this.setState({ seachProjectKey: val })

    let params = {
      code: '',
      id: '',
      nameCn: '',
      nameEn: ''
    }

    if (val === '') {
      this.confirmStore.setProject(params)
    }
  }
  handleAddressBook = () => {
    if (this.globalStore.userInfo.isUserAddressBook) {
      webService.user.getUserAddressBook().then((d) => {
        this.setState({
          users: d.data.data,
          showAddreessBook: true,
        }, () => {
          let fr = new ForgeRouter(() => {
            let { hash } = document.location;
            if (hash === '#/confirm') {
              this.setState({
                showAddreessBook: false
              })
            }
          });
          fr.onpopstate();
        })
      }, (error) => {
        Toast.show({
          // 拉起通讯录失败
          content: this.t('confirm.k049'),
          duration: 1000,
        });
      })
    } else {
      Dialog.show({
        // 请先到个人中心中导入通讯录
        content: this.t('confirm.k050'),
        actions: [[
          // 确认
          {
            key: 'goCenter', text: this.t('confirm.k051')},
        ]],
        closeOnAction: true
      })
      return;
    }
  }

  /**
   * 获取项目编号
   */
  getProjects = async (code = "") => {
    let { data } = await webService.order.getProjects({code: code})
    if (data.code === 0) {
      if (data.data.length > 0) {
        this.setState({
          filteredItems: data.data
        })
      } else {
        this.setState({
          filteredItems: []
        })
      }
    }
  }
    // 显示错误提示信息
    showMessageBox(url){
      Dialog.clear(); 
      this.messageBoxRef.show(url)
      let fr = new ForgeRouter(() => {
        this.messageBoxRef.hide('messageBoxRef')
      });
      fr.onpopstate();
    }
}

export default withTranslation()(Confirm);