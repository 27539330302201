import { observable, action, makeObservable, computed } from "mobx";

class RuningOrderStore {

    constructor() {
        makeObservable(this)
    }

    @observable waitingTime = 0;
    @observable cars = [];
    @observable carLabels = []
    @observable carLength = 0;
    @observable carLabelLength = 0;
    @observable activeLevel = 1;


    /**
     * 清空存储
     */
    @action.bound clear() {
        this.waitingTime = 0;
        this.cars = [];
        this.carLabels = [];
        this.carLength = 0;
        this.carLabelLength = 0;
    }

    /**
     * 自增等待时间
     */
    @action.bound addWaitingTime() {
        this.waitingTime++;
    }

    /**
     * 设置等待接单车型
     * @param {*} cars 
     */
    @action.bound setCars(cars) {
      let carLength = 0
        for (let i in cars) {
          carLength += cars[i].list.length;
        }
        this.cars = cars;
        this.activeLevel = this.cars[0]?.id;
        this.carLength = carLength

    }

    /**
     * 设置等待接单标签车型
     * @param {*} cars 
     */
    @action.bound setCarLabels(cars) {
      let carLabelLength = 0
      for(let i in cars) {
          for(let j in cars[i].data) {
            let car = cars[i].data[j];
            carLabelLength += car.list.length;
          }
      }
      this.carLabels = cars;
      this.carLabelLength = carLabelLength;
      this.activeLevel = this.carLabels[0]?.dynamicCode || 1
    }

    /**
     * 设置选中 tab
     * @param {*} cars 
     */
    @action.bound setActiveLevel(code) {
      this.activeLevel = code
    }

    /**
     * 所有车型数量
     * @param {*} cars 
     */
    @computed get allCarLength() {
      return this.carLength + this.carLabelLength
    }


  

}

// eslint-disable-next-line
export default new RuningOrderStore();