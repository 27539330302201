import { observable, action, makeObservable } from "mobx";

class ServiceCenterStore {

  constructor() {
    makeObservable(this)
  }
  // 是否从异常订单点击的投诉
  @observable isConfirmAbnormal = false
  
  // 设置投诉状态
  @action.bound setConfirmAbnormal(bool) {
    this.isConfirmAbnormal = bool
  }
}

// eslint-disable-next-line
export default new ServiceCenterStore();