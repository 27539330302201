import { observable, action, makeObservable } from "mobx";

class EstimateStore {

    constructor() {
        makeObservable(this)
    }

    /**
     * 乘车人
     */
    @observable passenger = {}

    /**
     * 确认页面依赖数据
     */
    @observable dependency = {
        scene: {},
        accounts: [],
        projects: [],
        extraServices: [],
        coupons: [],
        projectNum: '',
        showProject: false
      }

    @observable customCarList = []

    @observable dissatisfied = false

    @observable projectsTitle = false
    /**
     * 所选企业账户
     */
    @observable account = {};

    /**
     * 所属项目
     */
    @observable project = {};

    /**
     * 所选卡券
     */
    @observable coupon = undefined

    /**
     * 预付费
     */
     @observable prepaid = {}
     
    // sanofi会议
    @observable sanofiMeeting = {};

    @observable jinDuInfo = {
      approverInfo: {},
      caseInfo: {},
      costCenterInfo: {},
      costCenterCode: '',
      costCenterName: '',
      companyCode: '',
      constCenterList: [],
      staffCode: '',
      sourceStaffCode: '',
      approverName: '',
      approverList: [],
      caseCode: '',
      caseName: '',
      caseList: [],
      showCaseItem: false
    }

    @action.bound init() {
        this.passenger = {};
        this.dependency = {
            scene: {},
            accounts: [],
            projects: [],
            extraServices: [],
            coupons: [],
            showProject: false
        }
        this.project = {};
        this.customCarList = []
        this.coupon = undefined;
        this.sanofiMeeting = {}
        this.jinDuInfo = {
          approverInfo: {},
          caseInfo: {},
          costCenterInfo: {},
          costCenterCode: '',
          costCenterName: '',
          companyCode: '',
          constCenterList: [],
          staffCode: '',
          sourceStaffCode: '',
          approverName: '',
          approverList: [],
          caseCode: '',
          caseName: '',
          caseList: [],
          showCaseItem: false
        }
    }

    @action.bound clear() {
        this.init()
    }

    @action.bound setPassenger(passenger) {
      if (passenger.phone) {
        this.passenger = {
          ...passenger
        };
      }
    }

    @action.bound setPassengerPhone(phone) {
        this.passenger.phone = phone;
    }

    @action.bound setPassengerName(name) {
      this.passenger.name = name;
    }

    @action.bound setShowPhone(bool) {
      this.passenger.flagPhone = bool;
    }

    @action.bound setFlagName(bool) {
      this.passenger.flagName = bool;
    }

    @action.bound setDependency(dependency) {
        // let extraServices = dependency.extraServices
        // for(let j in extraServices) {
        //   if (extraServices[j].code === "ES0001") {
        //     extraServices[j].checked = true
        //   }
        // }
        this.dependency = dependency;
        if (!this.account.id) {
            this.account = this.dependency.accounts[0];
        }
    }

    @action.bound setProject(project) {
        this.project = project;
    }

    @action.bound setAccount(accountId) {
        this.account = this.dependency.accounts.filter(a => {return a.id === accountId}).map(a => {return {id: a.id, name: a.name}})[0];
    }

    /**
     * 勾选增值服务
     * @param {*} item 
     * @param {*} v 
     */
    @action.bound checkExtraServices(item, v) {
        for (let i in this.dependency.extraServices) {
            let _item = this.dependency.extraServices[i];
            if (_item.disabled === false && _item.id === item.id) {
                _item.checked = v;
            }
        }
    }

    /**
     * 判断是否选中了指定代码的增值服务
     * @param {*} code 
     * @returns 
     */
    @action.bound isCheckedExtraServicesForCode(code) {
        for (let i in this.dependency.extraServices) {
            let _item = this.dependency.extraServices[i];
            if (_item.checked && _item.code === code) {
                return true;
            }
        }
        return false;
    }

    /**
     * 设置所选卡券
     * @param {*} coupon 
     */
    @action.bound setCoupon(coupon) {
        this.coupon = coupon;
    }

    /**
     * 设置用车原因
     * @param {*} usedReason 
     */
    @action.bound setUsedReason(usedReason) {
        this.dependency.scene.usedReason = usedReason;
    }

    /**
     * 设置预付费相关数据
     */
    @action.bound setPrepaid(info) {
      this.prepaid = info
    }
    /**
     * 控制确认叫车按钮状态
     */
    @action.bound setDissatisfied(bool) {
      let data = this.customCarList.filter((d) => d.rules[0].required)
        
      let flag = data.every(item => item.value && item.value.length > 0)

      if (!flag) {
        this.dissatisfied = true
      } else {
        this.dissatisfied = bool
      }
    }

    /**
     * 获取自定义信息
     */
    @action.bound setCustomCarList(list) {
      this.customCarList = list
      if (this.customCarList.length === 0) {
        this.dissatisfied = false
      } else {
        for(let i = 0; i <  this.customCarList.length; i++) {
          // 施维雅预算代码多选默认一条数据直接选择
          if (this.customCarList[i].rules[0].autoCheck && this.customCarList[i].items && this.customCarList[i].items.length === 1) {
            this.customCarList[i].value = this.customCarList[i].items[0].value
            this.customCarList[i].labelValue = this.customCarList[i].items[0].label
          }
          if (this.customCarList[i].rules[0].required && !this.customCarList[i].value) {
            this.dissatisfied = true
            break
          }
        }
      }
    }

    @action.bound setCustomSearchList(list, id) {
      // 类似安进企业使用该方法
      for(let i = 0; i <  this.customCarList.length; i++) {
        if (this.customCarList[i].id === id) {
          this.customCarList[i].items = list
          this.customCarList[i].initItems = list
        }
      }
    }

    @action.bound setCustomCarAddList(val, index, type, id, special = '') {
      this.customCarList.forEach((item, idx) => {
        if (type === 'phone' && index === idx) {
          item.value = val
        } else if (type === 'number' && index === idx) {
          item.value = val
        } else if (type === 'email' && index === idx) {
          item.value = val
        } else if (type === 'textArea' && index === idx) {
          item.value = val
        } else if (val === 'checkListClick' && type === 'textSearch' && index === idx) {
          item.visible = true
        } else if (type === 'textSearch' && index === idx && val !== 'checkListClose') {
          item.value = val
        }
        // else if (type === 'radio' && index === idx) {
        //   item.items.forEach((v, i) => {
        //       if (index === idx && v.value === val) {
        //         item.value = val
        //         item.labelValue = v.label
        //       }
        //     })
        // } 
        else if ( index === idx && val === 'textSearchClick') {
          item.visible = true
        }
        else if ( index === idx && val === 'checkListClick') {
          item.visible = true
        } else if ( index === idx && val === 'checkListClose') {
          item.visible = false
        }else if ( index === idx && item.visible && type==='checked' && item.id === id) {
          if (special) {
            item.value = val[0]
          } else {
            item.value = val
          }
          let label = []
          if (item.initItems && item.initItems.length > 0) {
            item.initItems && item.initItems.forEach(v => {
                if (val.includes(v.value)) {
                    val.checked = true
                    label.push(v.label)
                    val.checked = false
                    if (special) {
                      item.visible = false
                    }
                } else {
                    val.checked = false
                }
            })
          } else {
            // 适配裁决号等类似数据
            item.items && item.items.forEach(v => {
              if (val.includes(v.value)) {
                  val.checked = true
                  label.push(v.label)
              } else {
                  val.checked = false
              }
            })
          }
          item.labelValue = label.join(',')
        }else if(index === idx && type === 'searchList' && item.id === id){
          if (item.initItems && item.initItems.length > 0) {
            item.items=item.initItems.filter(position=>{
             return position.label.indexOf(val)!==-1
            })
          } else {
            // 适配裁决号等类似数据
            item.items=item.items.filter(position=>{
              return position.label.indexOf(val)!==-1
             })
          }
        } 
      })

      if (this.customCarList.length > 0) {

        let data = this.customCarList.filter((d) => d.rules[0].required)
        
        let flag = data.every(item => item.value && item.value.length > 0)
        if (!flag) {
          this.dissatisfied = true
        } else if(flag) {
          this.dissatisfied = false
        }
      }


    }

    /**
     * 设置绿色出行勾选状态
     * @param {*} bool 
     */
    @action.bound setCheckLowCarbon(bool) {
      this.dependency.checkLowCarbon = bool
    }
    @action.bound setProjectsTitle(bool) {
      this.projectsTitle = bool
    }

    // 设置项目编号海目星需求
    @action.bound setProjectNum(val) {
      this.dependency.projectNum = val
    }

    // 设置sanofi会议
    @action.bound setSanofiMeeting(meeting) {
        this.sanofiMeeting = meeting;
    }

    @action.bound setCostCenterInfo(info) {
      this.jinDuInfo.costCenterInfo = info;
    }

    @action.bound setApproverInfo(info) {
      this.jinDuInfo.approverInfo = info;
    }

    @action.bound setCaseInfo(info) {
      this.jinDuInfo.caseInfo = info;
    }

    @action.bound setCostCenter(costCenterName, costCenterCode, companyCode) {
      this.jinDuInfo.costCenterName = costCenterName;
      this.jinDuInfo.costCenterCode = costCenterCode;
      this.jinDuInfo.companyCode = companyCode;
    }

    @action.bound setCostCenterList(constCenterList) {
      this.jinDuInfo.constCenterList = constCenterList
    }

    @action.bound setApprover(approverName, staffCode, sourceStaffCode = '') {
      this.jinDuInfo.approverName = approverName;
      this.jinDuInfo.staffCode = staffCode;
      this.jinDuInfo.sourceStaffCode = sourceStaffCode;
    }
    @action.bound setApproverList(approverList) {
      this.jinDuInfo.approverList = approverList
    }

    @action.bound setCase(caseName, caseCode) {
      this.jinDuInfo.caseName = caseName;
      this.jinDuInfo.caseCode = caseCode;
    }

    @action.bound setCaseList(caseList) {
      this.jinDuInfo.caseList = caseList
    }

    @action.bound setShowCaseItem(bool) {
      this.jinDuInfo.showCaseItem = bool
    }
}

// eslint-disable-next-line
export default new EstimateStore();

