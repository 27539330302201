import React from 'react'
import style from './runing.scss';
import { inject, observer } from 'mobx-react'
import { Dialog, Badge, Popup, Toast, Modal } from 'antd-mobile'


import Menu from './../component/callcar/menu'

import CountDown from './../component/callcar/countDown'
import SmartWaitingOrders from './../component/callcar/smartWaitingOrders'
import ManualWaitingOrders from './../component/callcar/manualWaitingOrders'
import SmartCarWaitingOrders from './../component/callcar/smartCarWaitingOrders'
import RuningView from './../component/callcar/runingView'
import ShowWait5s from './../component/callcar/showWait5s'
import NoneDriverReply from './../component/callcar/noneDriverReply'
import IPathMap from './../component/utils/iPathMap'
// import QQMap from './../component/utils/qqMap'
import RuningCharterCar from './../component/callcar/runingCharterCar'
import DispatchResponseOrder from '../component/callcar/dispatchResponseOrder';


import images from './../util/images'
import webService from '../util/webService';
import WebSocketHelper from '../util/wss';
import direction from '../util/direction';

// import { oauthType } from './../util/config'


// import WaitTimeCount from './../component/callcar/waitTimeCount'

import { stateToRuningTitleCn } from '../util/paramsHelper';
import { base64ToString } from '../util/utils';
import moment from 'moment'

import { findLogoSrc } from '../util/logoCache'

import { setWebViewTitle } from './../util/webViewHelper'

import { withTranslation } from "react-i18next";

import i18n from 'i18next'
import loger from '../util/loger'



// import gc from './../util/globalConfiguration'

@inject('runingOrderStore', 'globalStore', 'mainStore', 'estimateStore')
@observer
class Runing extends React.Component {

    state = {
        isShowMenu: false,
        orderOver: false,
        baseInfo: {},
        driverArrivedTime: undefined,
        driverTakedTime: undefined,
        scene: {},
        orderDetail: {},
        routeInfo: {
            time: -1,
            distance: -1
        },
        airportPickup: undefined,
        isShowWaitCountDown: false,
        isVisible: false,
        isReassignment: false,
        isBookingFail: false,
        proxyPhone: '',
        isBookingOrder: false,
        bookingOrderState: '',
        isScheduledApproaching: false,
        charterCarCancel: true, // 判断包车取消或者已完成隐藏用车操作栏，只包车生效
        replaceCallPhone: '',
        driver: {},
        isSameMsg: false,
        weakSignal: false,
        timeList: [], // 接驾预估时间配置
        distanceList: [], // 接驾预估里程配置
        language: null,
        isManuallyCancel: false
    }
    isReadOnly = this.props.isReadOnly;
    globalStore = this.props.globalStore;
    runingOrderStore = this.props.runingOrderStore;
    mainStore = this.props.mainStore;
    estimateStore = this.props.estimateStore;

    //AMap = undefined;
    iPathMapRef = undefined
    //map = undefined

    showMarkerBookingBeginEd = false;

    showMarkerBusBeginEd = false;

    cancelRes = null;

    timer60s = null

    timerWaiting = 0;

    wss = undefined;
    showWait5sRef = undefined;
    runingViewRef = null;

    Child = undefined;

    t = this.props.t

    get isMenuIcon() {
        if (this.state.baseInfo && this.state.baseInfo.serviceType && this.state.baseInfo.serviceType !== 20) {
            return true
        } else if (this.state.baseInfo && this.state.baseInfo.serviceType && this.state.baseInfo.serviceType === 20 && this.state.baseInfo.state > 1) {
            return true
        }
        return false
    }

    get showMenu() {
        return !this.isReadOnly && this.isMenuIcon
    }

    // 司机信号弱展示
    get isWeakSignal() {
        if (this.state.weakSignal && [2, 3, 4].includes(this.state.baseInfo.state)) {
            if (this.state.baseInfo.serviceType === 1) {
                return true
            } else if ((this.state.baseInfo.serviceType === 2 || this.state.baseInfo.serviceType === 6) && this.isAllowShowDriving()) {
                return true
            }
        }
        return false
    }

    /**
     * 是否显示不持支第三通话的提示文字
     */
    get showNotSupport3Call() {
        let { orderDetail, baseInfo } = this.state;
        if (![2, 3, 4].includes(baseInfo.state)) {
            // 订单状态不属于有司机的状态，不需要提示
            return false
        }

        // 已登录用户取得当前手机号，未登录则手机号为空字符串
        let currentUserPhone = (this.globalStore.userInfo && this.globalStore.userInfo.phone) || ''
        // 下单人用司机号码
        let phoneForUser = (orderDetail.driverInfo && orderDetail.driverInfo.phoneForUser) || ''
        if (!orderDetail.driverInfo) {
            // 如果还未收到司机信息，则不需要显示提示文本
            return false
        }

        if (!orderDetail.driverInfo.vehicleNo) {
            // 无司机车牌信息，不需要提示
            return false
        }

        if (!currentUserPhone) {
            // 当前没有身份，当作乘车人，乘车人不需要显示提示
            return false
        } else if (currentUserPhone === baseInfo.passengerPhone) {
            // 当前有身份，当前登录人手机号与乘车人手机号相同，不需要显示提示
            return false
        } else {
            // 当前有身份，且当前登陆人不是用车人，即为代叫车，代叫车时，如果没有下单人用的司机手机号，则显示提示
            return phoneForUser ? false : true
        }

    }

    /**
     * 判断是否设置跳转行程优化页
     */
    get showOrderOptimize() {
        return this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.ongoing_order_optimize
    }

    get language() {
        if (this.state.language) {
            return this.state.language
        }
        if (this.globalStore.userInfo.language) {
            return this.globalStore.userInfo.language
        }
        return 1
    }

    // 是否隐藏标签车UI
    get isUserLabelCarUI() {
      return this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.main_use_label_car_ui ? true : false
    }

    changeShowMenu = (isShow) => {
        this.setState(() => {
            return {
                isShowMenu: isShow
            }
        });
    }

    hideMenu = () => {
        this.changeShowMenu(false);
    }

    render() {
        let { userInfo } = this.globalStore;
        return <div className={style.container}>
            {
                //  包车
                (() => {
                    if (this.state.baseInfo.serviceType === 20 && this.state.baseInfo.state === 1) {
                        return (
                            <div className={style.bus}>
                                <RuningCharterCar t={this.props.t} baseInfo={this.state.baseInfo} cancelOrder={this.cancelOrder}></RuningCharterCar>
                            </div>
                        )
                    }
                })()
            }
            <>
                <div className={`${style.amapContainer} ${this.t('main.k000') === 'en' ? style.amap : ''}`} id="amap_container">
                    {
                        (() => {
                            if (this.state.baseInfo && this.state.baseInfo.state <= 1 && this.state.baseInfo.createTime) {
                                return <CountDown t={this.props.t} createTime={this.state.baseInfo.createTime} />
                            }
                        })()
                    }
                    <IPathMap t={this.props.t} ref={(dom) => { this.iPathMapRef = dom ? dom.getMapRef() : undefined }} ></IPathMap>
                    {/* <QQMap ref={(dom) => { this.qqMapRef = dom }} ></QQMap> */}
                </div>
                {
                    this.isWeakSignal || this.showNotSupport3Call
                        ? <div className={`${style.messagebox} ${this.showMenu ? style.indent : ''}`}>
                            {
                                // 当前司机侧信号弱，位置更新延迟，请稍后。
                                this.isWeakSignal
                                    ? <div>{this.t('runing.k0139')}</div>
                                    : <></>
                            }
                            {
                                // 平台不支持三方通话，请用乘车手机号拨打电话
                                this.showNotSupport3Call
                                    ? <div>{this.t('runing.k0140')}</div>
                                    : <></>
                            }
                        </div>
                        : <></>
                }

                {
                    this.showMenu
                        ? <div className={style.menuIcon} onClick={() => {
                            this.setState({
                                isShowMenu: true
                            })
                        }}>
                            <Badge content={this.mainStore.badgeSum !== 0 ? this.mainStore.badgeSum : null} style={{ '--top': '22%', '--right': '16%' }}>
                                <div className={style.box}></div>
                            </Badge>
                        </div>
                        : <></>
                }
                {
                    this.state.isShowMenu ? <div>
                        <Popup
                            visible={this.state.isShowMenu}
                            onMaskClick={this.hideMenu}
                            position='left'
                            bodyStyle={{ minWidth: '50vw' }}
                        >
                            <Menu hideMenu={this.hideMenu} t={this.props.t}></Menu>
                        </Popup>
                    </div> : <></>
                }



                <div>
                    {
                        (() => {
                            if (this.state.baseInfo.state <= 1 && !this.isReadOnly && this.state.baseInfo.serviceType !== 20 && !this.state.orderDetail.driverInfo) {
                              if (userInfo.useCarMode === 1) {
                                return <div className={style.waiting}><SmartWaitingOrders t={this.props.t} orderId={this.orderId} history={this.props.history} cancelOrder={this.cancelOrder} /></div>
                              } else if (this.isUserLabelCarUI) {
                                return <div className={style.waiting}><SmartCarWaitingOrders isBookingOrder={this.state.isBookingOrder} departTime={this.state.baseInfo.departTime} serviceType={this.state.baseInfo.serviceType} onRef={node => this.Child = node} t={this.props.t} orderId={this.orderId} history={this.props.history} cancelOrder={this.cancelOrder} loadOrderBaseInfo={this.loadOrderBaseInfo} clearTimer={() => clearTimeout(this.timer60s)} /></div>
                              } else {  
                                return <div className={style.waiting}><ManualWaitingOrders isBookingOrder={this.state.isBookingOrder} departTime={this.state.baseInfo.departTime} serviceType={this.state.baseInfo.serviceType} onRef={node => this.Child = node} t={this.props.t} orderId={this.orderId} history={this.props.history} cancelOrder={this.cancelOrder} loadOrderBaseInfo={this.loadOrderBaseInfo} clearTimer={() => clearTimeout(this.timer60s)} /></div>
                              }
                            } else {
                                // 预约失败展示ui
                                if (this.state.isBookingFail) {
                                    return (<div className={style.waiting}>
                                        <NoneDriverReply departTime={this.state.baseInfo.departTime} t={this.props.t} history={this.props.history} />
                                    </div>)
                                } else {
                                    if ((this.state.orderDetail.driverInfo || (this.state.driver && this.state.driver.driverName)) && !this.state.isBookingFail && this.state.baseInfo.state >= 2) {
                                        return (
                                            <div className={style.waitingPanel}>
                                                <RuningView isGreenTravel={this.state.baseInfo.isGreenTravel} t={this.props.t} orderId={this.orderId} history={this.props.history}
                                                    cancelOrder={this.cancelOrder}
                                                    isScheduledApproaching={this.state.isScheduledApproaching}
                                                    isSameMsg={this.state.isSameMsg}
                                                    baseInfo={this.state.baseInfo}
                                                    driverArrivedTime={this.state.driverArrivedTime}
                                                    driverTakedTime={this.state.driverTakedTime}
                                                    orderDetail={this.state.orderDetail}
                                                    routeInfo={this.state.routeInfo}
                                                    scene={this.state.scene}
                                                    airportPickup={this.state.airportPickup}
                                                    loadOrderBaseInfo={this.loadOrderBaseInfo}
                                                    isReadOnly={this.isReadOnly}
                                                    proxyPhone={this.state.proxyPhone}
                                                    flightNumber={this.state.baseInfo.flightNumber}
                                                    ref={(dom) => { this.runingViewRef = dom; }}
                                                    timeList={this.state.timeList}
                                                    distanceList={this.state.distanceList}
                                                />
                                            </div>)
                                    }
                                }
                            }
                        })()
                    }
                </div>

                {
                    this.state.orderOver
                        ? (
                            <div className={style.over}>
                                <div className={style.img}>
                                    {
                                        (() => {
                                            if ((this.state.baseInfo.state === 5 || this.state.baseInfo.state === 6) && this.state.baseInfo.serviceType !== 20) {
                                                return <img src={this.language === 1 ? images.order_finish : images.order_finish_en} alt={this.t('runing.k002')} />
                                            }
                                        })()
                                    }
                                    {
                                        (() => {
                                            // if (this.state.baseInfo.state === 7 && !this.state.isVisible) {
                                            //   return <img src={images.order_cancel} alt={this.t('runing.k001')} />
                                            // }
                                            if (this.state.baseInfo.state === 7 && this.state.baseInfo.serviceType !== 20) {
                                                return <img src={this.language === 1 ? images.order_cancel : images.order_cancel_en} alt={this.t('runing.k001')} />
                                            }
                                        })()
                                    }
                                    {/* <img src={images.order_finish} alt={this.t('runing.k002')} /> */}
                                </div>
                            </div>
                        )
                        : (<></>)
                }
                {
                  this.state.baseInfo?.dispatchConfig?.residuePopupTime > 0 ?
                  <DispatchResponseOrder 
                    t={this.props.t}
                    popupTime={Number(this.state.baseInfo?.dispatchConfig?.residuePopupTime)} 
                    outTime={Number(this.state.baseInfo?.dispatchConfig?.outTime)} 
                    dispatchFeeAmount={this.state.baseInfo?.dispatchConfig?.dispatchFeeAmount} 
                    orderId={this.state.baseInfo?.id}>
                  </DispatchResponseOrder> : <></>
                }


                <Modal visible={this.state.isShowWaitCountDown && this.state.baseInfo.state === 1} title={this.t('runing.k069')} closeOnMaskClick={false}
                    content={
                        <ShowWait5s isGreenTravel={this.state.baseInfo.isTickedGreenTravel} carMode={this.state.baseInfo.carMode} t={this.props.t} ref={(dom) => { this.showWait5sRef = dom; }}></ShowWait5s>
                    }
                ></Modal>
                {/* 该功能未联调，盲写，待接口和状态推送开发完毕联调 */}
                {/* {this.state.isVisible && !this.isReadOnly ? <WaitTimeCount t={this.props.t} backTrack={this.backTrack} resetOrder={this.resetOrder}></WaitTimeCount> : ''} */}
            </>
        </div>
    }

    async componentDidMount() {
        // 默认不是司乘同显单
        direction.openCachePath = false
        //console.log('visShowBadge', this.isShowBadge)
        this.orderId = this.props.match.params.id;
        this.loadOrderBaseInfo((coreOrderId) => {
            this.wss = new WebSocketHelper(window.satoken, coreOrderId, this.orderId, this.receiveWss);
        })
        // if (!this.map) {
        //   await this.qqMapRef.initMap()
        //   this.map = this.qqMapRef.getMap()
        //   this.qqMapRef.setMapOffset({x:0, y: -150})
        //   this.drawMap();
        // }
        await this.iPathMapRef.initMap()
        this.iPathMapRef.setMapOffset({ x: 0, y: -150 })
        this.drawMap()

        this.loadBadgeCount();
        this.onVisibilitychange();
    }

    componentWillUnmount() {
        if (this.wss) {
            this.wss.close();
        }
        if (this.iPathMapRef) {
            this.iPathMapRef.resetMapOffset({ x: 0, y: 0 })
        }
        clearInterval(this.timerWaiting)
        clearTimeout(this.timer60s)
        this.timer60s = null
        localStorage.setItem('isAddCar', '')
        this.runingOrderStore.clear();
        Dialog.clear()
    }

    componentWillReceiveProps(newProps) {
        const id = newProps.match.params.id;
        loger.info('订单进行中', '更换orderId', id)
        if (id !== this.orderId) {
            this.orderId = newProps.match.params.id
            this.setState({
                isVisible: false
            })
            this.loadOrderBaseInfo((coreOrderId) => {
                this.wss = new WebSocketHelper(window.satoken, coreOrderId, this.orderId, this.receiveWss);
            })
        }
    }

    /**
     * 挂载监听挂起的事件
     */
    onVisibilitychange = () => {
        if (window.onVisibilitychanged || localStorage.getItem('ignorevisibilitychange')) {
            return
        }
        document.addEventListener("visibilitychange", () => {
            if (document.hidden) {
                // 页面挂起的时候无需动作
                return
            } else {
                let hash = window.location.hash
                if (hash && (hash.startsWith('#/runing/') || hash.startsWith('#/watchShare/'))) {
                    window.location.reload()
                }
            }
        })
        window.onVisibilitychanged = true
    }

    /**
     * 刷新代办任务数
     */
    loadBadgeCount = () => {
        if (!this.isReadOnly) {
            this.mainStore.refreshBadgeCount();
        }

    }

    /**
     * 接受websokcet推送消息
     * @param {*} data 
     */
    receiveWss = (resp) => {
        if (this.weakSignalTimer) {
            clearTimeout(this.weakSignalTimer)
        }
        this.setState({
            weakSignal: false
        })
        this.weakSignalTimer = setTimeout(() => {
            if ([1, 2, 3, 4].includes(this.state.baseInfo.state))
                this.setState({
                    weakSignal: true
                })
        }, 10000);

        if (!resp.data) {
            let data = resp;
            resp = {
                code: 0,
                data: data
            }
        }
        loger.info('订单进行中', '接收到ws推送原始数据', JSON.stringify(resp))
        //eslint-disable-next-line
        let { baseInfo, orderDetail, airportPickup } = this.state;

        // resp.data.multiDriverOrder
        if (resp.code === 0 && baseInfo.state === 1 && resp.data.multiDriverOrder) {
            // 司机接单, 为了伪造多司机接单的假象,在接单成功后显示多司机分配的5s倒计时窗口
            this.showWait5s(resp).then(() => {
                this.receiveWss_core(resp);
            });
            return;
        }

        if (baseInfo.state && resp.data.orderState && Number(resp.data.orderState) === 1 && Number(baseInfo.state) > Number(resp.data.orderState)) {
            // 司机接单后订单取消，回归了等待接单状态，则页面自刷新一次
            window.location.reload()
        }

        if (Number(baseInfo.state) === 1 && Number(resp.data.orderState) === 2) {
            // 等待接单状态，收到司机接单消息，应清理一波弹出框
            Dialog.clear()
        }

        this.receiveWss_core(resp);
    }

    receiveWss_core = async (resp) => {
        let { baseInfo, orderDetail, airportPickup } = this.state;

        if (resp.code === 0) {
            // 途径点完成进度
            if (resp.data.passingPoints && resp.data.passingPoints.length) {
                if (baseInfo.passingPoints && baseInfo.passingPoints.length) {
                    for (let i in baseInfo.passingPoints) {
                        if (resp.data.passingPoints[i]) {
                            baseInfo.passingPoints[i].passed = true
                        }
                    }
                }
            }
            if (resp.data.orderState) {
                if (resp.data.orderState > 1) {
                    clearTimeout(this.timer60s);
                    this.timer60s = null;
                }
                // 如果有代叫车小号则展示
                if (resp.data.proxyPhone) {
                    this.setState({
                        proxyPhone: resp.data.proxyPhone
                    })
                }
                // 如果有新中台 id 则刷新页面
                if (resp.data && resp.data.newCoreOrderId) {
                    if (resp.data.newCoreOrderId !== baseInfo.coreOrderId) {
                        window.location.reload();
                    }
                }

                // 正常实时单等 this.state.baseInfo.serviceType !== 20
                if (resp.data.orderState === 7 && !this.state.isVisible && !this.state.isBookingOrder && baseInfo.serviceType !== 20) {
                    this.onReceiveCancelOrderState();
                    return
                }
                if (baseInfo.state !== resp.data.orderState) {
                    // 订单状态又变动,刷新代办事项数量
                    this.loadBadgeCount();
                }
                if ([1, 2, 3, 4].includes(baseInfo.state) && resp.data.orderState > baseInfo.state && resp.data.orderState !== 7) {
                    this.loadOrderBaseInfo(() => {}, resp.data.orderState);
                    setTimeout(() => {
                        this.loadOrderBaseInfo(() => {}, resp.data.orderState);
                    }, 2000);
                }

                if (resp.data.orderState === 1 && resp.data.isBookingOrder) {
                    clearTimeout(this.timer60s);
                    this.timer60s = null;
                    window.location.reload();
                }

                // 添加预约管家功能后修改该判断
                if ((resp.data.orderState > baseInfo.state) && resp.data.orderState !== 7) {
                    baseInfo.state = resp.data.orderState;
                }

            }
            if (resp.data.driverArrivedTime) {
                this.setState({
                    driverArrivedTime: resp.data.driverArrivedTime
                })
            }

            if (resp.data.prePayFlag) {
                this.loadOrderBaseInfo();
            }

            if (resp.data.driverInfo) {
                orderDetail.driverInfo = resp.data.driverInfo;
            }
            if (resp.data.orderState >= 2 && (resp.data.servicePhone || this.state.replaceCallPhone)) {
                // 使用代叫客服手机号
                orderDetail.driverInfo.replaceCallPhone = resp.data.servicePhone ? resp.data.servicePhone : this.state.replaceCallPhone
            }
            if (resp.data.source) {
                orderDetail.source = resp.data.source;
            }
            if (resp.data.driverPosition) {
                orderDetail.driverPosition = resp.data.driverPosition;
            }
            // 是否接力单
            if (resp.data.isRelay !== undefined) {
                orderDetail.isRelay = resp.data.isRelay
            }
            // 上一单目的地坐标
            if (resp.data.preOrder !== undefined) {
                orderDetail.preOrder = resp.data.preOrder
            }
            // 是否司乘同显单
            if (resp.data.hasEstRoute) {
                direction.openCachePath = true
            }
            // 接到了运力路径
            if (resp.data.coordList) {
                direction.addCachePath({
                    routeType: resp.data.routeType,
                    coordList: resp.data.coordList,
                    distance: resp.data.distance,
                    duration: resp.data.duration,
                })
            }
            if (resp.data.airportPickup) {
                airportPickup = resp.data.airportPickup;
            }
            // 订单被改派提示内容 inprogress 改无司机接单, complete 改派有司机接单可能会没有司机接单
            if ((resp.data.orderState === 2 || resp.data.orderState === 3) && resp.data.changeDriver === 'inprogress') {

                orderDetail.driverInfo.avatar = images.oval
                orderDetail.source.carSource = '司机改派中'
                orderDetail.source.carLevelName =
                    orderDetail.driverInfo.vehicleNo = ''
                orderDetail.driverInfo.level = ''
                orderDetail.driverInfo.vehicleModel = ''
                orderDetail.driverInfo.vehicleColor = ''
                // Dialog.show({
                //   title: '系统提示',
                //   content: '订单已被改派请稍后',
                //   actions: [
                //       {
                //           key: 'go_set', text: '知道了',
                //           onClick: () => {
                //           }
                //       }
                //   ],
                //   closeOnAction: true
                // })
            }

            // 预约管家客服在运维后台编辑预约失败
            if (resp.data.orderState === 7 && resp.data.isBookingOrder) {
                this.setState({
                    isBookingFail: true
                })
                baseInfo.state = resp.data.orderState
                this.markFail()
                return
            }

            if (resp.data.orderState === 7 && baseInfo.serviceType === 20) {
                this.loadOrderBaseInfo()
            }

            this.setState({
                baseInfo: baseInfo,
                orderDetail: orderDetail,
                airportPickup: airportPickup
            }, () => {
                this.drawMap();
                setWebViewTitle(stateToRuningTitleCn(baseInfo.state, true, this.t, this.state.isBookingOrder, this.state.baseInfo.serviceType, baseInfo.charterCarCancel))
            })
        }
    }

    onReceiveCancelOrderState = async () => {
        let { data } = await webService.order.getBaseInfo({ orderId: this.orderId });
        if (data.code === 0) {
            if (data.data && data.data.baseInfo) {
                let baseInfo = data.data.baseInfo;
                if (baseInfo.state === 7 && baseInfo.cancelReason === '平台取消') {
                    this.setState({
                        isVisible: true
                    })
                    Dialog.show({
                        title: this.t('runing.k0110'),
                        content: this.t('runing.k0111'),
                        closeOnMaskClick: true,
                        actions: [
                            {
                                key: 'go_set', text: this.t('runing.k0112'),
                                onClick: () => {
                                    this.setState({
                                        isVisible: false
                                    })
                                    this.mainStore.init();
                                    this.props.history.goBack();
                                }
                            }
                        ],
                        onClose: () => {
                            this.mainStore.init();
                            this.props.history.goBack();
                        },
                        closeOnAction: true
                    })
                } else {
                    if (this.showOrderOptimize && !this.state.isManuallyCancel) {
                        if (data.data.baseInfo.state > 4) {
                            setTimeout(() => {
                                this.props.history.replace(`/orderdetails/${this.orderId}`)
                            }, 2000)
                        }
                    } else {
                        this.setState({
                            orderOver: data.data.baseInfo.state > 4 && data.data.baseInfo.serviceType !== 20
                        })
                    }
                }
            }
        }
    }

    /**
     * 显示等待5s
     */
    showWait5s = (resp) => {
        return new Promise((resolve, reject) => {
            let num = Math.round(resp.data.waitLength / 1000);
            this.setState({
                isShowWaitCountDown: true
            })
            this.showWait5sRef.start(num);

            setTimeout(() => {
                this.setState({
                    isShowWaitCountDown: false
                }, () => {
                    resolve()
                })


            }, num * 1000);
        })

    }

    /**
     * 载入订单基本信息
     */
    loadOrderBaseInfo = async (fn = () => {}, orderStatus = '') => {
        let d;
        try {
            d = await webService.order.getBaseInfo({ orderId: this.orderId, isReadonly: this.props.isReadOnly });
        } catch (exp) {
            console.error('loadOrderBaseInfo方法异常')
            console.error(d)
            Toast.show({
                icon: 'fail',
                content: this.t('runing.k004')
            })
        }

        try {
            loger.info('订单进行中', 'order.getBaseInfo主动查的订单原始数据', { orderId: this.orderId, responseText: d.request.responseText })
        } catch (e) {
            console.error(e)
        }

        if (d.data.code === 0) {
            try {
                // 途径点，为成功读取到，因此放在首行
                let _pathway = d.data.data.baseInfo.passingPoints;
                _pathway = _pathway && _pathway && _pathway.length ? _pathway : [];
                d.data.data.baseInfo.passingPoints = _pathway;
                if (d.data.data?.language === 1) {
                    i18n.changeLanguage('zh')
                } else if (d.data.data?.language === 2) {
                    i18n.changeLanguage('en')
                }

                let extraServices = JSON.parse(d.data.data.baseInfo.customInfo)
                if (extraServices && extraServices.extraServices) {
                    for (let i in extraServices.extraServices) {
                        if (extraServices.extraServices[i].code === "ES0005" && d.data.data.baseInfo.serviceType === 2) {
                            this.setState({
                                isBookingOrder: true
                            })
                            break;
                        }
                    }
                }
                if (d.data.data.baseInfo.coreOrderId && fn) {
                    fn(d.data.data.baseInfo.coreOrderId)
                }
                if (d.data.data.baseInfo.state > 1 && this.state.isBookingOrder) {
                    if (this?.iPathMapRef?.getMap()) {
                        // 有司机接单应隐藏等待接单的路径线条和点
                        this.showMarkerBookingBeginEd = false
                        this.iPathMapRef.clearDriving()
                    }
                }
                if (d.data.data.baseInfo.state > 1 && d.data.data.baseInfo.serviceType === 20) {
                    if (this?.iPathMapRef?.getMap()) {
                        this.showMarkerBusBeginEd = false;
                    }
                }
                // 预约管家获取预约信息
                if (d.data.data.baseInfo.coreOrderId && d.data.data.baseInfo.serviceType === 2 && this.state.isBookingOrder) {
                    await this.loadBookingOrderInfo(d.data.data.baseInfo.coreOrderId)
                }
                if (d.data.data.baseInfo.userId !== this.globalStore.userInfo.id) {
                    // 订单不属于当前登陆人
                    this.props.history.replace(`/watchShare/${this.orderId}`)
                }
                if (!d.data.data.cars) {
                    d.data.data.cars = [];
                } else {
                    for (let i in d.data.data.cars) {
                        let item = d.data.data.cars[i]
                        item.checkCount = item.list.length;
                        item.list = item.list.sort((a, b) => { return a.estimatePrice - b.estimatePrice });
                        for(let j in item.list) {
                            item.list[j].carSourceImg = findLogoSrc(item.list[j].carSourceId)
                        }
                    }
                }
                d.data.data.cars.sort((a, b) => a.id - b.id)
                let carLabels = d.data.data.labels || []
                carLabels.forEach(item => {
                  item.sideBarCount = item.data.filter(val => val.list.length > 0).length
                  item.data.forEach(val => {
                    val.isShow = false
                    val.checkCount = val.list.length
                    val.list = val.list.sort((a, b) => { return a.estimatePrice - b.estimatePrice });
                    val.list.forEach(v => {
                      v.carSourceImg = findLogoSrc(v.carSourceId);
                    })
                  })
                })
                this.runingOrderStore.setCars(d.data.data.cars);
                this.runingOrderStore.setCarLabels(carLabels || []);
                // 兼容同一司机接单 5 秒后再调用会隐藏提示框
                if (d.data.data.baseInfo.message && d.data.data.baseInfo.state === 2) {
                    this.setState({
                        isSameMsg: true
                    })
                    setTimeout(() => {
                        this.setState({
                            isSameMsg: false
                        })
                    }, 5000);
                }

                // d.data.data.baseInfo.customInfo = JSON.parse(d.data.data.baseInfo.customInfo)

                this.setState({
                    baseInfo: d.data.data.baseInfo,
                    driver: d.data.data.baseInfo.driver,
                    driverArrivedTime: d.data.data.driverArrivedTime,
                    driverTakedTime: d.data.data.driverTakedTime,
                    scene: d.data.data.scene ? d.data.data.scene : { isAllowChangeDest: d.data.data.baseInfo.allowChangeDest },
                    timeList: d.data.data.timeList,
                    distanceList: d.data.data.distanceList,
                    language: d.data.data?.language
                }, async () => {
                    // document.title = stateToRuningTitleCn(d.data.data.baseInfo.state);
                    // if (window.nativeCB && window.nativeCB.bridgeSetTitle) {
                    //     window.nativeCB.bridgeSetTitle(stateToRuningTitleCn(d.data.data.baseInfo.state));
                    // }
                    this.drawMap();
                    setWebViewTitle(stateToRuningTitleCn(d.data.data.baseInfo.state, false, this.t, this.state.isBookingOrder, this.state.baseInfo.serviceType, this.state.baseInfo.charterCarCancel))
                    let isAddCar = localStorage.getItem('isAddCar')
                    if (d.data.data.baseInfo.state === 1 && !d.data.data.baseInfo.isAppend && !isAddCar && d.data.data.baseInfo.serviceType !== 20) {
                        this.timing60s(60000)
                        localStorage.setItem('isAddCar', true)
                    } else {
                        clearTimeout(this.timer60s)
                        if (this.timer60s !== null) {
                            Dialog.clear()
                        }
                        this.timer60s = null
                    }
                });

                if (this.showOrderOptimize && !this.state.isManuallyCancel) {
                    if (d.data.data.baseInfo.state > 4) {
                        setTimeout(() => {
                            this.props.history.replace(`/orderdetails/${this.orderId}`)
                        }, 2000)
                    }
                } else {
                    this.setState({
                        orderOver: d.data.data.baseInfo.state > 4 && d.data.data.baseInfo.serviceType !== 20
                    })
                }

                if (d.data.data.baseInfo.state < 5) {
                    if (this.wss) {
                        if (!this.wss.isOpenState()) {
                            this.wss.open();
                        }
                    }
                }
                if (d.data.data.baseInfo.state === 7 && this.state.isBookingOrder) {
                    // 预约失败
                    this.setState({
                        isBookingFail: true
                    })
                    this.sdBookingRoute()
                }
            } catch (ex) {
                console.error('loadOrderBaseInfo方法成功后发生异常')
                console.error(d)
                Toast.show({
                    icon: 'fail',
                    content: this.t('runing.k004')
                })
            }


        } else {
            Toast.show({
                icon: 'fail',
                content: d.data.message ? d.data.message : this.t('runing.k003')
            })
            setTimeout(() => {
                this.props.history.replace('/');
            }, 2000);
        }




    }

    /**
     * 
     * 载入预约管家订单信息
     *
     */
    loadBookingOrderInfo = async (coreOrderId) => {
        let { data } = await webService.order.getBookingOrderInfo({ coreOrderId: coreOrderId })
        let { orderDetail } = this.state;
        if (data.code === 0) {
            this.setState({
                proxyPhone: data.data.proxyPhone,
                isBookingOrder: data.data.isBookingOrder,
                bookingOrderState: data.data.orderState,
                replaceCallPhone: data.data.proxyPhone ? data.data.servicePhone : ''
            })
            if (data.data.servicePhone && this.state.baseInfo.state >= 2) {
                orderDetail.driverInfo.replaceCallPhone = data.data.servicePhone
            }
        } else {
            Dialog.alert({
                content: data.message,
                closeOnMaskClick: true,
                confirmText: this.t('common.k003')
            })
        }
    }

    /**
     * 取消订单
     */
    cancelOrder = async (reason) => {
        this.setState({
          isManuallyCancel: true
        })
        if (this.state.baseInfo.state > 3) {
            // 行程开始后不可取消订单
            return;
        }

        // 预约管家预约单取消
        if (this.state.isBookingOrder && this.state.baseInfo.state === 1) {
            this.cancelRes = await Dialog.confirm({
                getContainer: false,
                bodyClassName: `${style.appointment}`,
                title: this.t('runing.k084'),
                cancelText: this.t('runing.k088'),
                confirmText: this.t('runing.k089'),
                content: <div style={{ textAlign: 'center' }}>{this.t('runing.k090')}</div>
            })
            if (!this.cancelRes && this.state.isBookingOrder && this.state.baseInfo.serviceType === 2) {
                this.claerOrderInfo(reason)
            }
        } else {
            // 正常订单取消
            this.cancelRes = await Dialog.confirm({
                content: (<>
                    {/* k0143 是否取消该笔订单 */}
                    <div style={{ fontSize: '16px', textAlign: 'center' }}>{this.state.baseInfo.serviceType === 20 ? this.t('runing.k0138') : this.t('runing.k005')}</div>
                    {
                        this.state.baseInfo.state < 2
                            ? <div style={{ textAlign: 'center' }}>{this.state.baseInfo.serviceType !== 20 ? this.t('runing.k006') : ''}</div>
                            : <></>
                    }

                </>),
                confirmText: this.state.baseInfo.serviceType === 20 ? this.t('common.k002') : this.t('runing.k007'),
                cancelText: this.state.baseInfo.serviceType === 20 ? this.t('common.k001') : this.t('runing.k008')
            })
            if (this.cancelRes) {
                // 包车取消司机已接单取消中状态
                if (this.state.baseInfo.serviceType === 20 && this.state.baseInfo.state !== 1) {
                    let { data } = await webService.order.cancelOrderForRental({ orderId: this.orderId, reason: '客户手动取消' })
                    if (data.code === 0) {
                        Toast.show({
                            content: this.t('runing.k0113'),
                            afterClose: () => {
                                console.log('after')
                            },
                        })
                        // this.runingViewRef.setCancelOrder()
                    }
                } else {
                    // 正常订单取消
                    this.claerOrderInfo(reason)
                }
            }
        }


    }

    claerOrderInfo = (reason) => {
        webService.order.cancelOrder({ orderId: this.orderId, reason: reason ? reason : '用户取消用车' }).then(d => {
            if (d.data.code === 0) {
                // 如果产生取消费用则跳转订单详情页面
                if (d.data.data?.fee > 0) {
                    setTimeout(() => {
                        this.props.history.replace(`/orderdetails/${this.orderId}`);
                    }, 2000)
                    return
                }
                // 用户主动取消成功以后,判断是否需要重新预估价
                if (this.globalStore.companyInfo.needUseCarToken && !this.globalStore.allowUseCar) {
                    // 关闭
                    // 订单已经取消
                    Dialog.alert({
                        content: this.t('runing.k083'),
                        confirmText: this.t('common.k003')
                    })
                    window.close();
                    return;
                }
                clearTimeout(this.timer60s)
                this.timer60s = null
                Dialog.clear()
                if (this.state.baseInfo.serviceType === 2 && !this.state.isBookingOrder) {
                    let beforeTime = moment(this.state.baseInfo.departTime).subtract(30, "m").format("YYYY-MM-DD HH:mm:ss");
                    let currentTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
                    let isCompare = moment(currentTime).isBefore(beforeTime);
                    if (!isCompare) {
                        // 预约时间小于当前时间预约单过期直接返回首页
                        this.props.history.replace('/');
                        return
                    }
                }

                // 用户主动取消成功以后,应恢复本单叫车参数,清理浏览器历史栈,模拟首页到预估页面的跳转
                if (d.data.data && d.data.data.orderParams && !this.state.isBookingOrder) {
                    let order_params = JSON.parse(base64ToString(d.data.data.orderParams));
                    let choosedEstimates = order_params.estimateStore.choosedEstimates
                    order_params.estimateStore.choosedEstimates = choosedEstimates.map(a => {
                        let _arr = a.split(',')
                        return {
                            carSource: _arr[0],
                            carSourceId: Number(_arr[1]),
                            carLevel: Number(_arr[2]),
                            labelCode: _arr[3] || '',
                            subCarType: _arr[4] || ''
                        }
                    })
                    this.mainStore.reset(order_params.mainStore,
                        this.globalStore.userInfo.phone !== order_params.confirmStore.passenger.phone ? order_params.confirmStore.passenger : undefined);
                    this.estimateStore.reset(order_params.estimateStore);
                    this.props.history.replace('/estimate');
                } else {
                    this.props.history.replace('/');
                }
            } else {
                Dialog.alert({
                    content: d.data.message,
                    confirmText: this.t('common.k003')
                })
            }
        });
    }

    /**
     * 绘制地图元素
     */
    drawMap = async () => {
        let { baseInfo, orderDetail } = this.state;
        if (baseInfo.state > 1 && baseInfo.serviceType === 20) {
            this.charteredBusMarker()
        }
        else if (baseInfo.state <= 1 && !this.state.isBookingOrder && this.state.baseInfo.serviceType !== 20) {
            this.showDepart();
        } else if (baseInfo.state > 1 && baseInfo.state < 5) {
            if (orderDetail.driverPosition && orderDetail.driverPosition.lng && this.isAllowShowDriving()) {
                this.showDriving();
            } else /*if (baseInfo.serviceType !== 1)*/ {
                console.log('showWaitStart')
                this.showWaitStart();
            }

        } else if (baseInfo.state > 4 && baseInfo.state < 7) {
            this.showDriving();
        } else if (this.state.isBookingOrder && baseInfo.state === 1) {
            this.sdBookingRoute()
        }
    }

    marker = undefined;
    markerBegin = undefined;
    markerEnd = undefined;
    /**
     * 绘制地图上起点
     */
    showDepart = () => {
        if (this.iPathMapRef && this.iPathMapRef.getMap()) {
            this.iPathMapRef.addBeginMarker({
                lat: this.state.baseInfo.departLat,
                lng: this.state.baseInfo.departLng
            });
        }
    }

    /**
     * 绘制等待司机坐标
     */
    showWaitStart = () => {
        this.showDepart();
        if (this.state.baseInfo.serviceType !== 1 && !this.isAllowShowDriving()) {
            this.setState({
                isScheduledApproaching: true
            })
        } else {
            this.setState({
                isScheduledApproaching: false
            })
        }

    }

    isAllowShowDriving = () => {
        let baseInfo = this.state.baseInfo;
        if (baseInfo.serviceType !== 1 && this.state.baseInfo.state === 2) {
            let totalMinutes = moment.duration(moment(baseInfo.departTime) - moment()).asMinutes();
            if (totalMinutes > 30) {
                return false;
            }

        }
        return true;
    }

    driving = undefined;
    /**
     * 绘制驾驶路径
     */
    showDriving = () => {
        if (this.mainStore.serviceType === 20) {
            return
        }
        if (!this.iPathMapRef) {
            return;
        }
        if (!this.iPathMapRef.getMap()) {
            return;
        }
        this.setState({
            isScheduledApproaching: false
        })

        switch (this.state.baseInfo.state) {
            case 2:
                this.sd2();
                break;
            case 3:
                this.sd3();
                break;
            case 4:
                this.sd4();
                break;
            case 5:
                this.sd5();
                break;
            case 6:
                this.sd5();
                break;
            default:
                loger.info('订单进行中', '无需绘制地图', { orderId: this.orderId, state: this.state.baseInfo.state })
                break;
        }
    }

    /**
     * 更新导航信息 （高德时代代码，目前已经作废）（高德时代代码，目前已经作废）
     * @param {*} status 
     * @param {*} result 
     */
    // updateRouteInfo = (status, result) => {
    //     if (status === 'complete') {
    //         let routeInfo = this.state.routeInfo;
    //         routeInfo.distance = result.routes[0].distance;
    //         routeInfo.time = Math.ceil(result.routes[0].time / 60);
    //         if (this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.ongoing_arriveTimeShorten) {
    //           if (routeInfo.time > 1) {
    //             routeInfo.time = Math.round(routeInfo.time * 0.8)
    //           }
    //         }
    //         if (routeInfo.distance > 1000) {
    //             routeInfo.distance = (routeInfo.distance % 1000 > 50) ? (routeInfo.distance / 1000).toFixed(1) : Math.ceil(routeInfo.distance / 1000);
    //             routeInfo.distanceUnitName = this.t('runing.k010');
    //         } else {
    //             routeInfo.distanceUnitName = this.t('runing.k011');
    //         }
    //         this.setState({
    //             routeInfo: routeInfo
    //         })
    //     } else {
    //         webService.logger.report(this.orderId, 'orderRuning', '获取驾车数据失败：', JSON.stringify({ status, result }))
    //     }
    // }

    /**
     * poi版更新导航信息
     * @param {*} status 
     * @param {*} result 
     */
    updatePathInfo = (result) => {
        let routeInfo = this.state.routeInfo;
        routeInfo.distance = result.distance;
        routeInfo.time = Math.round(result.duration);
        // if (this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.ongoing_arriveTimeShorten) {
        //   if (routeInfo.time > 1) {
        //     routeInfo.time = Math.round(routeInfo.time * 0.8)
        //   }
        // }

        // if (['17'].includes(oauthType) && this.state.baseInfo.state === 2) {
        //   routeInfo.distance = gc.computed.calculationTimeKM(routeInfo.distance, routeInfo.time).distance
        //   routeInfo.time = gc.computed.calculationTimeKM(routeInfo.distance, routeInfo.time).time.toFixed(0);
        // }

        // if (routeInfo.distance > 1000) {
        //     let _distance = routeInfo.distance / 1000
        //     _distance = Number(_distance.toFixed(1))
        //     routeInfo.distance = _distance;
        //     routeInfo.distanceUnitName = this.t('runing.k010');
        // } else {
        //     routeInfo.distanceUnitName = this.t('runing.k011');
        // }
        this.setState({
            routeInfo: routeInfo
        })
    }

    // /**
    //  * 绘制两点和连线
    //  * @param {*} position1 
    //  * @param {*} image1 
    //  * @param {*} position2 
    //  * @param {*} image2 
    //  */
    // showMark = (position1, image1, position2, image2, sizex = 40, sizey = 40) => {
    //     let icon1 = new this.AMap.Icon({
    //         size: new this.AMap.Size(sizex, sizey),
    //         image: image1,
    //         imageSize: new this.AMap.Size(sizex, sizey),
    //     })
    //     let icon2 = new this.AMap.Icon({
    //         size: new this.AMap.Size(sizex, sizey),
    //         image: image2,
    //         imageSize: new this.AMap.Size(sizex, sizey),
    //     });
    //     if (this.markerBegin) {
    //         this.markerBegin.setPosition(position1)
    //         this.markerBegin.setIcon(icon1);
    //     } else {
    //         this.markerBegin = new this.AMap.Marker({
    //             position: position1,
    //             icon: icon1,
    //             offset: new this.AMap.Pixel(-20, -40),
    //         });
    //         if (this.map) {
    //             this.map.add(this.markerBegin);
    //         }
    //     }

    //     if (this.markerEnd) {
    //         this.markerEnd.setPosition(position2)
    //         this.markerEnd.setIcon(icon2);
    //     } else {
    //         this.markerEnd = new this.AMap.Marker({
    //             position: position2,
    //             icon: icon2,
    //             offset: new this.AMap.Pixel(-20, -40),
    //         });
    //         if (this.map) {
    //             this.map.add(this.markerEnd);
    //         }
    //     }
    // }

    // polyline = undefined

    /**
     * 司机赶来接乘客
     */
    sd2 = async () => {
        let { baseInfo, orderDetail } = this.state;
        loger.track('订单进行中', '司机赶来接乘客', { orderId: this.orderId, state: 2, position: { lat: orderDetail.driverPosition.lat, lng: orderDetail.driverPosition.lng } })

        if (!this.iPathMapRef.watchLocationId) {
            this.iPathMapRef.watchPosition()
            console.log('状态2监听实时位置')
        }
        console.log('状态2动画')

        let res = await this.iPathMapRef.addRuningAnimation({
            lat: orderDetail.driverPosition.lat,
            lng: orderDetail.driverPosition.lng
        }, {
            lat: baseInfo.departLat,
            lng: baseInfo.departLng,
            poi: baseInfo.departPoi
        }, true, orderDetail.isRelay && orderDetail.preOrder ? orderDetail.preOrder : undefined)

        if (res.code === 0) {
            this.updatePathInfo(res)
        }

    }

    sd3 = () => {
        let { baseInfo, orderDetail } = this.state;
        loger.track('订单进行中', '司机已到达', { orderId: this.orderId, state: 3, position: { lat: orderDetail.driverPosition.lat, lng: orderDetail.driverPosition.lng } })

        this.iPathMapRef.drawdriverAndPassengerPosition({
            lat: baseInfo.departLat,
            lng: baseInfo.departLng
        }, {
            lat: orderDetail.driverPosition.lat,
            lng: orderDetail.driverPosition.lng
        })

        this.iPathMapRef.removeFence();
        this.iPathMapRef.removeRecommendMarkers();

    }

    sd4 = async () => {
        let { baseInfo, orderDetail } = this.state;
        loger.track('订单进行中', '司机送乘客', { orderId: this.orderId, state: 4, position: { lat: orderDetail.driverPosition.lat, lng: orderDetail.driverPosition.lng } })

        if (this.iPathMapRef.watchLocationId) {
            this.iPathMapRef.clearWatchPosition()
        }

        let res = await this.iPathMapRef.addRuningAnimation({
            lat: orderDetail.driverPosition.lat,
            lng: orderDetail.driverPosition.lng
        }, {
            lat: baseInfo.destLat,
            lng: baseInfo.destLng,
            poi: baseInfo.destPoi
        }, false, undefined, baseInfo.passingPoints)

        if (res.code === 0) {
            this.updatePathInfo(res)
        }
    }

    // 行程结束
    sd5 = () => {
        let { baseInfo } = this.state;
        loger.track('订单进行中', '行程结束', { orderId: this.orderId, state: 5, position: { lat: baseInfo.destLat, lng: baseInfo.destLng } })

        this.iPathMapRef.addDriving({
            lat: baseInfo.departLat,
            lng: baseInfo.departLng,
            locationName: baseInfo.pickupLocationName
        }, {
            lat: baseInfo.destLat,
            lng: baseInfo.destLng,
            locationName: baseInfo.destLocationName
        })
    }

    /**
     * 
     * @returns 预约管家等待接单
     */
    sdBookingRoute = async () => {
        if (!this.state.baseInfo.departLng) {
            return
        }
        if (this.showMarkerBookingBeginEd) {
            return
        }

        if (this.iPathMapRef && this.iPathMapRef.getMap()) {
            if (!this.markerBookishowMarkerBookingBeginEdngBegin && (this.state.baseInfo.state === 1 || this.state.baseInfo.state === 7)) {
                this.showMarkerBookingBeginEd = true;
                this.iPathMapRef.addDriving({
                    lat: this.state.baseInfo.departLat,
                    lng: this.state.baseInfo.departLng,
                    locationName: this.state.baseInfo.pickupLocationName
                }, {
                    lat: this.state.baseInfo.destLat,
                    lng: this.state.baseInfo.destLng,
                    locationName: this.state.baseInfo.destLocationName
                })
            }
        }

    }

    markFail = async () => {
        if (this.showMarkerBookingBeginEd) {
            return
        }
        if (this.iPathMapRef && this.iPathMapRef.getMap()) {
            this.showMarkerBookingBeginEd = true
            this.iPathMapRef.addDriving({
                lat: this.state.baseInfo.departLat,
                lng: this.state.baseInfo.departLng,
                locationName: this.state.baseInfo.pickupLocationName
            }, {
                lat: this.state.baseInfo.destLat,
                lng: this.state.baseInfo.destLng,
                locationName: this.state.baseInfo.destLocationName
            }, this.state.baseInfo.serviceType)
        }
    }

    charteredBusMarker = () => {
        if (this.showMarkerBusBeginEd) {
            return
        }
        if (this.iPathMapRef && this.iPathMapRef.getMap()) {
            this.iPathMapRef.addDriving({
                lat: this.state.baseInfo.departLat,
                lng: this.state.baseInfo.departLng,
                locationName: this.state.baseInfo.pickupLocationName
            }, {
                lat: this.state.baseInfo.destLat,
                lng: this.state.baseInfo.destLng,
                locationName: this.state.baseInfo.destLocationName
            }, this.state.baseInfo.serviceType)
        }
    }

    timing60s = (num) => {
        if (this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.ongoing_addcar && this.globalStore.userInfo.useCarMode === 2) {
            this.timer60s = setTimeout(() => {
                if (this.state.baseInfo && this.state.baseInfo.state <= 1 && this.timer60s !== null) {
                    Dialog.confirm({
                        getContainer: false,
                        bodyClassName: `${style.models}`,
                        confirmText: this.t('runing.k079'),
                        cancelText: this.t('runing.k080'),
                        content: (<>
                            <div>
                                <div>{this.t('runing.k081')}</div>
                                {this.t('runing.k082')}
                            </div>
                        </>),
                        onConfirm: () => {
                            clearTimeout(this.timer60s)
                            this.timer60s = null
                            Dialog.clear()
                            this.Child.handleShowPop()
                        },
                        onCancel: () => {
                            clearTimeout(this.timer60s)
                            this.timer60s = null
                            Dialog.clear()
                        }
                    })
                }
            }, num)
        }
    }

    // 重新派单户用户点击取消触发
    backTrack = () => {
        this.setState({
            isVisible: false
        })
        this.mainStore.init();
        this.props.history.goBack();
    }

    // 重新派单户用户点击确定触发
    resetOrder = async () => {
        this.setState({
            isVisible: false
        })
        let d = await webService.order.getAgainPlaceOrder({ orderId: this.orderId });
        if (d.data.code === 0 && !d.data.data.amount) {
            this.props.history.replace('/runing/' + d.data.data.orderId)
        } else if (d.data.data.amount > 0) {
            let prepaidInfo = {
                amount: d.data.data.amount,
                orderId: d.data.data.orderId,
                time: d.data.data.time
            }
            // 存储预支付返回金额、倒计时、等
            localStorage.setItem('prepaidInfo', JSON.stringify(prepaidInfo));
            localStorage.setItem('transaction_no', '');
            this.props.history.replace('/prepaid')
        } else {
            Dialog.confirm({
                content: d.data.message,
                cancelText: this.t('common.k001'),
                confirmText: this.t('common.k002')
            })
        }
    }
}

export default withTranslation()(Runing);