import React from 'react';
import style from './prepaid.scss';

import {inject, observer } from 'mobx-react'

import { Image, Button, Dialog, Toast } from 'antd-mobile'

import moment from 'moment'
import eruda from 'eruda'

import images from '../util/images';

import webService from '../util/webService';

import { ipay, dge, debounce, payType } from './../util/utils'
import { oauthType } from './../util/config'

import {customerServiceTelephone} from '../util/config'

import { withTranslation } from "react-i18next";

import loger from './../util/loger'

@inject('mainStore')
@observer
class Prepaid extends React.Component {

  state = {
    endDate: '',
    timer: null,
    countDown: '',
    payInfo: {
      paySources: '',
      paySucceed: null,
    },
    prepaidInfo: {
      amount: '',
      orderId: '',
      time: ''
    },
    isWxMini: true,
    isStop: true,
    orderState: null,
    isCountdown: true,
    isDisabled: false
  }

  mainStore = this.props.mainStore;

  orderTimer = undefined

  t = this.props.t


  /**
   * 
   * 支付详情
   */
  renderPrepaid = () => {
    return(
      <div className={style.top}>
        {
          (() => {
            if(this.state.payInfo.paySucceed) {
              return(
                <div className={style.paySucceed}>
                  <Image src={images.paySucceed} width={40} height={40} fit='fill'/>
                  <span>{this.t('prepaid.k001')}</span>
                </div>
              )
            } else {
              return <span>{this.t('prepaid.k002')}</span>
            }
          })()
        }
        <div className={style.money}>¥<span>{this.state.prepaidInfo.amount}</span></div>
        {
          (() => {
            if (!this.state.payInfo.paySucceed) {
              return(
                <div className={style.countDown}>{this.t('prepaid.k003')} &nbsp;<Image src={images.payTime} width={14} height={14} fit='fill' /> &nbsp;
                  <span>{this.state.countDown}</span>
                </div>
              )
            }
          })()
        }
        <div className={style.orderPay}>{this.t('prepaid.k004')}</div>
        {
          (() => {
            if (this.state.payInfo.paySucceed) {
              return(
                <div className={style.payBox}>
                  <div className={style.message}>
                    <span>{this.t('prepaid.k005')}</span>
                    <span>{this.t('prepaid.k006')}</span>
                  </div>
                  <div className={style.message}>
                    <span>{this.t('prepaid.k007')}</span>
                    {
                      (() => {
                        if (this.state.payInfo?.paySources === 1) {
                          return <span>{this.t('prepaid.k008')}</span>
                        } else if (this.state.payInfo?.paySources === 2) {
                          return <span>{this.t('prepaid.k009')}</span>
                        } else {
                          return <span>{this.t('prepaid.k024')}</span>
                        }
                      })()
                    }
                  </div>
                </div>
              )
            }
          })()
        }
      </div>
    )
  }

  /**
   * 
   * 支付按钮
   */
  renderButton = () => {
    return(
      (() => {
        if (this.state.payInfo.paySucceed) {
          return(
            <div className={style.look}>
              <Button onClick={debounce(() => this.getOrderState(), 500)} block color='primary' size='large'>
                {this.t('prepaid.k010')}
              </Button>
            </div>
          )
        } else {
          return(
            <div className={style.pay}>
              <Button onClick={this.prepaidPay} disabled={this.state.isDisabled} block color='primary' size='large'>
              {this.t('prepaid.k011')}
              </Button>
            </div>
          )
        }
      })()
    )
  }

  render() {
    return(
      <div className={style.wrap}>
        {this.renderPrepaid()}
        {this.renderButton()}
      </div>
    )
  }

  componentDidMount = async () => {

      window.addEventListener("popstate", this.backFun, false);
      let prepaidInfo;
      if (localStorage.getItem('prepaidInfo').length > 0) {
        prepaidInfo = JSON.parse(localStorage.getItem('prepaidInfo'));
      }
      
      if (prepaidInfo) {
        this.setState({
          prepaidInfo: {
            ...this.state.prepaidInfo,
            amount: prepaidInfo.amount,
            orderId: prepaidInfo.orderId,
            time: prepaidInfo.time
          }
        })
      } else {
        this.props.history.goBack()
      }
      document.addEventListener("visibilitychange", () => {
        if (document.visibilityState === 'visible') {
          if(dge() === 'wx-mini' || dge() === 'jd-mini'){
            let isWxMini = JSON.parse(localStorage.getItem('isWxMini'))
            if (isWxMini && this.state.isStop) {
              window.location.reload()
            }
          } else if(dge() === 'ding-ding') {
            this.getPrepaidState()
          }
        }
      });

      
      //eslint-disable-next-line
      this.state.timer = setInterval(() => {
        this.showTime(this.state.prepaidInfo.time)
      })

      if (this.state.isCountdown) {
        this.getPrepaidState()
      }
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.backFun, false);

    localStorage.setItem('appendCarTypePrePay', '');

    let transaction_no = localStorage.getItem('transaction_no');

    if (!transaction_no) {
      localStorage.setItem('prepaidInfo', '');
      clearInterval(this.state.timer)
    }
  }

  // 支付
  prepaidPay = () => {
    this.setState({
      isDisabled: true
    })

    let appendCarTypePrePay = localStorage.getItem('appendCarTypePrePay');

    let url = window.location.href

    let params = {
      orderId: this.state.prepaidInfo.orderId,
      amount: this.state.prepaidInfo.amount,
      appendCarTypePrePay: appendCarTypePrePay ? true : false,
      redirectUri: url, 
      paymentType: payType()
    }
    webService.order.getPrepaidPrePay(params).then(d => {
      if (d.data.data.transaction_no) {
        localStorage.setItem('appendCarTypePrePay', '');
        localStorage.setItem('transaction_no', d.data.data.transaction_no ? d.data.data.transaction_no : d.data.data.payOrderid);
        localStorage.setItem('isWxMini', true)
        if (dge() === 'jd-mini') {
          setTimeout(() => {
            this.setState({
              isDisabled: false
            })
          }, 2000)
          this.getPrepaidState()
        }
        if (oauthType === '1') {
          ipay('', d.data.data.payUrl);
        } else {
          ipay(d.data.data, '/prepaid');
        }
      }
    }).catch(err => {
      this.setState({
        isDisabled: false
      })
    })

  }

  /**
   * 获取支付状态
   */
  getPrepaidStatePay = async(payNo) => {
    return webService.order.getPrepaidStatePay(payNo)
  }

  /**
   * 返回清除弹窗
   */
  backFun = () => {
    Dialog.clear()
  }
  
  /**
   * 定时
   */
  showTime = (time) => {
    let start = moment(new Date())
    let end = moment(new Date(time))
    let diff = end.diff(start);
    let timediff = Math.round(diff / 1000);
    //获取还剩多少天
    // var day = parseInt(timediff / 3600 / 24);
    //获取还剩多少小时
    var hour = parseInt((timediff / 3600) % 24);
    //获取还剩多少分钟
    var minute = parseInt((timediff / 60) % 60);
    //获取还剩多少秒
    var second = timediff % 60;
    //输出还剩多少时间

    if (hour <= '0' && minute <= '0' && second <= '0' && this.state.payInfo.paySucceed) {
      this.setState({
        countDown: '00:00'
      })

      clearInterval(this.state.timer)

      Dialog.show({
        title: this.t('prepaid.k012'),
        bodyClassName: `${style.maskOrder}`,
        confirmText: '',
        content: (
          <>
            <div className={style.through}>
            {this.t('prepaid.k022')}
            </div>
          </>
        ),
        closeOnAction: true
      })

      setTimeout(() => {
        localStorage.setItem('transaction_no', '');
        this.mainStore.init();
        this.props.history.goBack()
      }, 3000)

    } else if (hour <= '0' && minute <= '0' && second <= '0' && !this.state.payInfo.paySucceed) {
      this.setState({
        countDown: '00:00',
        isCountdown: false
      })
      clearInterval(this.state.timer)

      Dialog.show({
        title: this.t('prepaid.k012'),
        bodyClassName: `${style.maskOrder}`,
        confirmText: '',
        content: (
          <>
            <div className={style.through}>
            {this.t('prepaid.k013')}
            </div>
          </>
        ),
        closeOnAction: true
      })

      setTimeout(() => {
        localStorage.setItem('transaction_no', '');
        this.mainStore.init();
        this.props.history.goBack()
      }, 3000)
      // Dialog.show({
      //   title: this.t('prepaid.k012'),
      //   bodyClassName: `${style.maskOrder}`,
      //   content: (
      //     <>
      //       <div className={style.through}>
      //       {this.t('prepaid.k013')}
      //       </div>
      //     </>
      //   ),
      //   actions: [
      //     {
      //       key: 'cancel',
      //       text: this.t('prepaid.k014'),
      //       onClick: () => {
      //         // 返回上一层
      //         sessionStorage.setItem('transaction_no', '');
      //         this.mainStore.init();
      //         this.props.history.goBack()
      //       }
      //     }
      //   ],
      //   closeOnAction: true
      // })
    } else {
      if (hour > '0') {
        this.setState({
          countDown: this.timerFilter(hour) + ":" + this.timerFilter(minute) + ":" + this.timerFilter(second)
        })
      } else {
        this.setState({
          countDown: this.timerFilter(minute) + ":" + this.timerFilter(second)
        })
      }
    }
  }

  /**
   * 补 0 操作
   */
  timerFilter = (params) => {
    if (params - 0 < 10) {
      return "0" + params;
    } else {
      return params;
    }
  }


  /**
   * 查询支付状态
   */
  getPrepaidState = async (bool = false) => {
    let transaction_no = localStorage.getItem('transaction_no');

      if (transaction_no || bool) {

        Toast.show({
          icon: 'loading',
          content: this.t('prepaid.k015'),
          duration: 0
        })
      
        let params = {
          PayOrderid: transaction_no
        }

        localStorage.setItem('isWxMini', false)

        // 走接口查询
        let result = await this.getPrepaidStatePay(params);
        if (result.data.code === 0) {
          loger.info(`订单号：${this.state.prepaidInfo?.orderId}`, '预付费状态查询', `返回数据：${JSON.stringify(result.data.data)}`)
          // webService.logger.report(this.state.prepaidInfo.orderId, 'prepaid', '预付费状态查询', `返回数据：${JSON.stringify(result.data.data)}`)
          Toast.clear();
          if (result.data.data.payState === 1 && this.state.isCountdown) {
            this.setState({
              payInfo: {
                ...this.state.payInfo,
                paySucceed: false
              }
            })
            Dialog.show({
              // 支付确认
              title: oauthType === '15' ? this.t('prepaid.k025') : this.t('prepaid.k016'),
              bodyClassName: `${style.maskOrder}`,
              content: (
                    <>
                      <div className={style.through}>{this.t('prepaid.k017')}</div>
                    </>
              ),
              actions: [
                [
                  {key: 'go_set', text: this.t('prepaid.k018'), 
                  onClick: () => {
                    this.setState({
                      isDisabled: false
                    })
                    localStorage.setItem('transaction_no', '');
                  }}, 
                  {key: 'cancel', text: this.t('prepaid.k019'), bold: true, onClick: async () => {

                    let result = await this.getPrepaidStatePay(params)
                    
                    if (result.data.code === 0) {
                      loger.info(`订单号：${this.state.prepaidInfo?.orderId}`, '预付费状态查询', `返回数据：${JSON.stringify(result.data.data)}`)
                      // webService.logger.report(this.state.prepaidInfo.orderId, 'prepaid', '预付费状态查询', `返回数据：${JSON.stringify(result.data.data)}`)
                      if (result.data.data.payState === 1||result.data.data.payState===3) {
                        Dialog.show({
                          title: oauthType === '15' ? this.t('prepaid.k025') : this.t('prepaid.k016'),
                          content: (
                            <>
                              <div className={style.through}>
                              {this.t('prepaid.k020')} <span className={style.phone} onClick={() => {
                                this.setState({
                                  isStop: false
                                })
                                setTimeout(() => {
                                  window.top.location.href = `tel://${customerServiceTelephone}`;
                              }, 200);
                              }}>{customerServiceTelephone}</span>
                              </div>
                            </>
                          ),
                          bodyClassName: `${style.maskOrder}`,
                          actions: [
                            {
                              key: 'cancel',
                              text: this.t('prepaid.k021'),
                              onClick: () => {
                                // 返回上一层
                                localStorage.setItem('prepaidInfo', '');
                                localStorage.setItem('transaction_no', '');
                                this.mainStore.init();
                                clearInterval(this.state.timer)
                                this.props.history.goBack()
                              }
                            }
                          ],
                          closeOnAction: true
                        })
                      } 
                      else if (result.data && result.data.data && result.data.data.payState === 2) {
                        this.setState({
                          payInfo: {
                            ...this.state.payInfo,
                            paySucceed: true,
                            paySources: result.data.data.tranChannel
                          }
                        })
                        
                        // sessionStorage.setItem('prepaidInfo', '');
                        // sessionStorage.setItem('transaction_no', '');

                        clearInterval(this.state.timer)
                        
                      }
                    } else {
                      loger.info(`订单号：${this.state.prepaidInfo?.orderId}`, '预付费状态查询', `返回数据：${JSON.stringify(result.data)}`)
                      // webService.logger.report(this.state.prepaidInfo.orderId, 'prepaid', '预付费状态查询', `返回数据：${JSON.stringify(result.data)}`)
                    }
                  }}
              ]],
              closeOnAction: true
            })
          } else if (result.data && result.data.data && result.data.data.payState === 2 && this.state.isCountdown) {
            this.setState({
              payInfo: {
                ...this.state.payInfo,
                paySucceed: true,
                paySources: result.data.data.tranChannel
              }
            })
            // 支付成功
            // sessionStorage.setItem('prepaidInfo', '');
            // sessionStorage.setItem('transaction_no', '');
            clearInterval(this.state.timer)
          }
        } else {
          loger.info(`订单号：${this.state.prepaidInfo?.orderId}`, '预付费状态查询', `返回数据：${JSON.stringify(result.data)}`)
          // webService.logger.report(this.state.prepaidInfo.orderId, 'prepaid', '预付费状态查询', `返回数据：${JSON.stringify(result.data)}`)
        }
    
      }
  }

  /**
   * 获取大额预付费支付订单是否生成
   */
  getOrderState = async () => {
    let prepaidInfo = JSON.parse(localStorage.getItem('prepaidInfo'));
    webService.order.getPrepaidCheckPlaceOrder({orderId: prepaidInfo.orderId}).then(res => {
      if (res.data.data.isSuccess) {
        localStorage.setItem('prepaidInfo', '');
        localStorage.setItem('transaction_no', '');
        this.props.history.replace('/runing/' + prepaidInfo.orderId)
      } else {
        Dialog.show({
          bodyClassName: `${style.maskOrder}`,
          confirmText: '',
          content: (
            <>
              <div className={style.through}>
              {this.t('prepaid.k023')}
              </div>
            </>
          ),
          closeOnAction: true
        })
  
        setTimeout(() => {
          localStorage.setItem('transaction_no', '');
          this.mainStore.init();
          this.props.history.goBack()
        }, 3000)
      }
    })
  }

  firstClickTime = 0;
  totleClick = 0;
    /**
     * 启动调试模式
     */
  debugMenu = () => {
      if (new Date().getTime() - this.firstClickTime > 2000) {
          this.firstClickTime = new Date().getTime()
      } else {
          this.totleClick = this.totleClick + 1;
          if (this.totleClick >= 50) {
            eruda.init()
            // let script = document.createElement('script');
            // script.type = 'text/javascript';
            // script.onload = () => { 
            //     // eslint-disable-next-line
            //     var vConsole = new VConsole();
            // };
            // script.src = "https://cdn.bootcdn.net/ajax/libs/vConsole/3.9.0/vconsole.min.js";
            // document.getElementsByTagName('head')[0].appendChild(script); 
            console.log('成功触发', this.totleClick)
            console.log(dge())
          }
      }
  }
}

export default withTranslation()(Prepaid);
