import webService from './webService'


class PromotionHelper {
    
    load = async (d) => {
        let res = await webService.promotion.getPromotion(d);
        if(res.data.code === 0 && res.data.data.list.length > 0) {
            let list = res.data.data.list;
            return list;
        } 
        // else {
        //     return [{
        //         url: 'https://devmobile.imtpath.net/huodong.png'
        //     }]
        // }
    }

    exec = async (item) => {

        let res = await webService.promotion.exec(item.url, {});
        if (res.data.code === 0 && res.data.data.imgUrl) {
            let imgUrl = res.data.data.imgUrl;
            return imgUrl;
        }

    }

}

// eslint-disable-next-line
export default new PromotionHelper();